.pagination {
  margin: 0;

  &__list {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    &:nth-last-child(2) {
      .pagination__nav {
        border-right: none;
      }
    }
  }

  &__nav {
    position: relative;
    border-style: solid;
    border-color: $red rgba($red, .21);
    border-width: 1px 1px 1px 0;
    width: 55px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .3s ease-in-out;

    @include btn-hover() {
      background-color: rgba($red, .21);
    }

    .is-active & {
      background-color: rgba($red, .21);
    }
  }

  &__nav--prev,
  &__nav--next {
    display: block;
    border: none;
    background-color: $red;
    color: $white;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .icn {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .icn  svg {
      fill: currentColor;
    }

    @include btn-hover() {
      background-color: $red;
    }
  }

  &__nav--prev {
    @include notch-top-left(14px);
  }

  &__nav--next {
    @include notch-bottom-right(14px);
  }
}


.pagination__container {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
}


.tui-page-btn {
  font-weight: 400;
  width: 55px;
  height: 50px;
  position: relative;
  border-style: solid;
  border-color: $red rgba($red, .21);
  border-width: 1px 1px 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .3s ease-in-out;
  cursor: pointer;

  &.tui-first,
  &.tui-last {
    display: none;
  }

  &:hover,
  &.tui-is-selected {
    background-color: rgba($red, .21);
  }

  &.tui-prev,
  &.tui-next {
    border: none;
    background-color: $red;
    color: $white;
  }

  &.tui-prev {
    @include notch-top-left(14px);
  }

  &.tui-next {
    @include notch-bottom-right(14px);
  }

  .tui-ico-prev,
  .tui-ico-next {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .tui-ico-prev {
    background-image: url('../img/icons/icn-arrow-left-white.svg');
  }

  .tui-ico-next {
    background-image: url('../img/icons/icn-arrow-right-white.svg');
  }


  &.tui-is-disabled {
    cursor: not-allowed;

    .tui-ico-prev,
    .tui-ico-next {
      opacity: .5;
    }
  }
}
