.grid--related .block {
  a {
    &::after {
      display: none;
    }
  }
}




.grid--related .block--news {
  .block__visual {
    &:before {
      display: block;
      padding-top: ((200 / 320) * 100%);
      width: 100%;
      content: ' ';
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  figure {
    margin: 0;
  }

  .block__content {
    margin-top: 0;
  }

  .info-block {
    color: $blue;
    padding: 10px;

    &::before {
      display: none;
    }

    &__title {
      color: $red;
    }

    &__postdate {
      margin-top: 0;
    }

    &__button {
      margin: -45px -10px 0 0;
    }
  }

  .btn {
    background-color: $red;
    color: white;
  }
}
