.title {
  font-family: $title-font-family;
  position: relative;
  text-transform: uppercase;
  color: $blue;
}


.title--main {
  $fs: 26;
  font-size: rem($fs);
  font-weight: 900;
  letter-spacing: ls(4.8, $fs);
  position: relative;
  padding-top: .5em;
  position: relative;
  line-height: 1;
  margin: 50px 0;

  &,&::after {
    mix-blend-mode: multiply;

    // @supports not (mix-blend-mode: multiply) {

    // }
  }

  &::before {
    @include lines-horizontal($red);
    content: '';
    display: block;
    position: absolute;
    width: 100vw;
    top: 2px;
    height: 42px;
    left: -10px;
    transform: translateX(-100%);
  }

  &::after {
    content: attr(data-title);
    color: $red;
    position: absolute;
    top: 0;
    left: 0;
  }

  @include above($tablet) {
    $fs: 32;
    font-size: rem($fs);
    letter-spacing: ls(4.8, $fs);
  }
}




.title--sub {
  $fs: 22;
  font-size: rem($fs);
  font-weight: 900;
  letter-spacing: ls(3, $fs);

  span {
    padding-bottom: em(7, 29);
    display: inline-block;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 7px;
      background-image: repeating-linear-gradient(45deg, transparent, $blue, transparent 1px, transparent 3px);
    }
  }

  @include above($tablet) {
    $fs: 29;
    font-size: rem($fs);
    letter-spacing: ls(3, $fs);
  }
}
