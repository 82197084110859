
.carousel--events:not(.slick-slider),
.carousel--events:not(.slick-initialized) {
  display: flex;
}

.carousel--events {
  margin: 40px 0 0;


  .slick-track {
    padding: 40px 0;
  }

  .block--event {
    position: relative;
    transition-property: transform;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;

    .block-meta__logo-bar,
    .info-block__tags,
    .info-block::before {
      display: none;
    }

    .block-meta {
      left: 0;
      right: 0;
    }

    .block__visual {
      margin: 0;
      overflow: hidden;
      transition-property: background-color;
      transition-duration: .3s;
      transition-timing-function: ease-in-out;
      outline: none;

      img {
        transition: transform .3s ease-in-out;
      }
    }

    .info-block {
      opacity: 0;
      z-index: -999;
      transition-property: opacity, z-index;
      transition-duration: .3s;
      transition-timing-function: ease-in-out;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    .info-block__date {
      margin: 0;
    }
  }

  .slick-current {
    .block--event {
      z-index: 1;
      transform: scale(1.1);
      .block__visual {
        background-color: $red;
      }

      .info-block {
        opacity: 1;
        z-index: 1;
      }
    }
  }
}

.carousel--events {
  .slick-dots {
    list-style: none;
    padding: 0;
    margin: 40px 0 20px;
    width: 100%;
    text-align: center;

    li {
      display: inline-block;
    }

    button {
      @include circle(12px);
      background-color: rgba(white, .5);
      margin: 0 4px;
      position: relative;
      overflow: hidden;
      transition-property: background-color;
      transition-duration: .3s;
      transition-timing-function: ease-in-out;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    li.slick-active button {
      background-color: $red;
    }
  }
}
