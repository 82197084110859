.grid--related {
  $gutter: 20px;

  max-width: 1030px;
  margin: 40px 0 40px 0;
  justify-content: space-between;

  .col {
    @include above($tablet-xs) {
      width: calc(50% - #{($gutter)});
    }

    @include above($desktop-sm) {
      width: calc(33.333% - #{($gutter)});
    }

  }
}
