$tag-height: 22px;

$tagColors: (
  'lightorange': $lightorange,
  'lightblue': $lightblue,
  'lightgreen': $lightgreen
);

.tag {
  $fs: 13;
  font-size: rem($fs);
  letter-spacing: ls(1.4, $fs);
  height: $tag-height;
  color: $white;
  text-transform: lowercase;
  line-height: $tag-height;
  display: inline-block;

  & + & { margin-left: 10px; }
  
  &__inner {
    display: flex;
    flex-wrap: nowrap;
  }

  &__left {
    width: 10px;
    height: $tag-height;
  }

  &__main {
    height: $tag-height;
    margin-left: -2px;
    padding: 0 7px;
    background-color: $black;
  }

  svg {
    display: block;
    width: 10px;
    height: $tag-height;
    fill: $black;
  }

  @each $name, $color in $tagColors {
    &--#{$name} {
      .tag__main { background-color: $color; }
      svg { fill: $color; }
    }
  }

}


// .tag {
//   $fs: 13;

//   @include notch-top-left-bottom-left(7px);
//   font-size: rem($fs);
//   letter-spacing: ls(1.4, $fs);
//   height: $tag-height;
//   color: $white;
//   text-transform: lowercase;
//   line-height: $tag-height;
//   display: inline-block;
//   padding: 0 7px 0 14px;
//   position: relative;

//   &::before {
//     content: '';
//     display: block;
//     width: 4px;
//     height: 4px;
//     border-radius: 2px;
//     background-color: $red;
//     position: absolute;
//     left: 3px;
//     top: 50%;
//     transform: translateY(-50%);
//   }

//   .event-preview--alt &::before {
//     background-color: $white;
//   }


//   @each $name, $color in $tagColors {
//     &--#{$name} {
//       background-color: $color;
//     }
//   }

// }