.ui-datepicker-calendar {
  font-family: $base-font-family;
  color: $blue;
  border-collapse: collapse;

  thead th {
    font-size: 20px;
    font-weight: 400;
    width: 50px;
    height: 70px;
    vertical-align: middle;
    text-align: center;
  }

  tbody td {
    font-size: 16px;
    border: 1px solid #ddd;
    width: 50px;
    height: 30px;
    vertical-align: middle;
    text-align: center;

    a {
      display: block;
      width: 100%;
      height: 100%;
      line-height: 28px;
    }

    &.ui-datepicker-unselectable {
      border: none;
    }

    &.ui-datepicker-today {
      background-color: rgba($red, .21);
      color: white;
    }

    &.ui-datepicker-current-day {
      background-color: $red;
      color: white;
    }
  }

}

.ui-datepicker-header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $blue;

  .ui-datepicker-prev {
    order: 1;
  }

  .ui-datepicker-next {
    order: 3;
  }

  .ui-datepicker-title {
    order: 2;
  }
}

.ui-datepicker-title {
  font-size: 20px;
  font-weight: 600;
}

.ui-datepicker-next,
.ui-datepicker-prev {
  width: 35px;
  height: 35px;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    top: 12px;
    border: {
      style: solid;
      color: $blue;
      width: 2px 2px 0 0;
    }
  }

  .ui-icon {
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
    }
  }

}

.ui-datepicker-prev {
  &::before {
    right: 10px;
    transform: rotate(-135deg);
  }
}

.ui-datepicker-next {
  &::before {
    left: 10px;
    transform: rotate(45deg);
  }
}
