
.practical-info {

  &__title {
    $fs: 30;
    font-size: rem($fs);
    font-weight: 900;
    letter-spacing: ls(2.21, $fs);
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
    color: $red;
    text-align: center;
    margin-bottom: -.2em;
    mix-blend-mode: multiply;
  }

  &__inner {
    background-color: $blue;
    color: white;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .icon-list__item {
    padding: 16px 20px;
    border-bottom: 1px solid rgba(white, .25);

    &:last-child {
      // padding-bottom: 0;
      // border: none;
    }

    .table {
      width: calc(100% - 45px);
      td:nth-child(2) {
        text-align: right;
      }
    }
  }

  &__buy {
    padding: 20px 0;
    text-align: center;
  }
}


.date-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tag-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
