.logo {
  width: 128px;
  height: 28px;
}

.logo a {
  background-image: url('../img/logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  
  &,&::before {
    display: block;
    width: 100%;
    height: 100%;
  }

  &::before {
    content: '';
  }
}