// ==========================================================================
// Playground
// A playground for trying things out.
// Go wild! But don't forget to clear before deployment.
// ==========================================================================


.stylesheet-section {
  margin: 50px 0;
  padding-bottom: 50px;
  border-bottom: 1px solid rgba($blue, .25);
}

.filler {
  min-height: 50vh;
}