.block--training {
  margin: 60px 0;

  &.blue-bg {
    $bar-height: 65px;

    &::after {
      content:'';
      background-color: $white;
      display: block;
      position: absolute;
      width: 100vw;
      height: $bar-height;
      bottom: -50px;
      left: 50%;
      margin-left: 0;
      transform: skew(-45deg);

      @include above($tablet) {
        bottom: 0;
      }

      @include above($desktop) {
        margin-left: -200px;
      }
    }

  }

  .block__content {
    max-width: 700px;
    margin: 30px 0 40px 0;

    p {
      margin: 0;
    }

    p + p {
      margin-top: 20px;
    }

    .btn {
      margin-top: 30px;
    }
  }
}
