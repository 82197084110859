.nav--combined {

  .nav__item {
    display: block;
    font-size: rem(16);
    font-weight: 700;
    font-family: $title-font-family;

    a {
      display: inline-block;
      padding: 0 0 .25em 0;
      margin: 0 0 .75em 0;
    }

    &.has-children {
      position: relative;

      .icn {
        @include size(20px);
        overflow: hidden;
        position: relative;
        vertical-align: middle;
        margin: -5px 0 0 5px;
      }

      svg {
        fill: white;
      }
    }

    &.has-children {
      position: relative;
    }

    &.is-open {
      .icn {
        transform: rotate(-180deg);
      }

      > .subnav {
        display: block;
      }
    }

    &.is-closed {
      > .subnav {
        display: none;
      }
    }
  }
}


.subnav {
    display: block;
    font-size: rem(14);
    font-weight: 400;
    padding-left: 1em;
  }
