$menu-width: 240px;

body.show-menu {
  left: -100vw;

  .off-screen-menu {
    left: 0;
  }

  @include above($menu-width) {
    left: -($menu-width);

    .off-screen-menu {
      left: calc(100vw - #{$menu-width});
    }
  }

}


[data-mobile-menu="open"] {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: auto;
  display: flex;
  overflow: visible;

  &::before {
    position: absolute;
    background-color: $blue;
    z-index: 0;
    transform: skew(-45deg);
    width: auto;
    height: 49px;
    top: -7px;
    right: -60px;
    left: -20px;
  }

  .icn {
    position: relative;
    z-index: 1;

    .hamburger {
      &__inner {
        background-color: white;
      }
    }
    svg {
      fill: white;
    }
  }

  .btn__text {
    display: inline-block;
    width: auto;
    color: white;
    position: relative;
    z-index: 1;
  }

  @include above($tablet) {
    display: none;
  }
}

.off-screen-menu {
  background-color: $blue;
  color: $white;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  left: 100vw;
  z-index: 999;
  transition: left .3s ease-in-out;
  overflow: scroll;

  &__inner {
    padding: 60px 20px 20px 25px;
    position: relative;
    display: block;
  }

  [data-mobile-menu="close"] {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  @include above($menu-width) {
    width: $menu-width;
  }

  @include above($tablet) {
    display: none;
  }

  .form--header-search {
    width: 100%;
    max-width: 100%;

    .field::after {
      background-color: $red;
    }
  }

  &__menu {
    margin-top: 25px;
  }

  &__social {
    border-top: 1px solid rgba(white, .3);
    padding-top: 30px;
    margin-top: 60px;
    h5 {
      margin: 0 0 14px 0;
    }
  }
}

