.form--contact {
  margin-top: 40px;
  margin-bottom: 40px;

  @include above($tablet-xs) {
    margin-top: 60px;
    margin-bottom: 60px;
    max-width: 520px;
  }

}

.fieldgrid {

  @include above($tablet-xs) {
    display: flex;
    justify-content: space-between;
  }
}

.field-wrapper--firstname,
.field-wrapper--lastname {

  @include above($tablet-xs) {
    width: 45%;
  }
}

.field-wrapper--email {
  @include above($tablet-xs) {
    width: 55%;
  }
}

.field-wrapper--phone {
  @include above($tablet-xs) {
    width: 35%;
  }
}
