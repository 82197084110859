.nav--secondary {
  $fs: 13;
  font-size: rem($fs);
  letter-spacing: ls(0.68, $fs);
  font-family: $title-font-family;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #f2f2f2;
  position: relative;

  > ul > li {
    &.is-active,
    &:hover,
    &:focus {
      > a { color: $red; }
    }
  }

  @include above($tablet) {
    height: 40px;
    color: $white;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;

    > ul {
      height: 100%;
      text-align: right;
      display: inline-flex;
      position: absolute;
      align-items: center;
      right: 0;
      padding: 0 30px 0 20px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -20px;
        left: 0;
        bottom: 0;
        background-color: $blue;
        transform: skew(-45deg);
      }
    }

    > ul > li {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      position: relative;
      z-index: 1;
      height: rem(50);
    }

    svg {
      fill: currentColor;
    }
  }

  @include above($desktop) {
    margin-right: 15px;
    width: auto;
    height: auto;
    color: $blue;
    position: relative;
    border: none;

    > ul {
      text-align: left;
      height: auto;
      // display: inline-block;
      position: relative;
      right: auto;
      padding: 0;

      &::before {
        display: none;
      }
    }

    > ul > li {
      padding: 0 10px;
      display: inline-flex;
    }
  }

  @include above($desktop-md) {
    margin-right: 25px;

    > ul > li { padding: 0 14px; }
  }

  @include above($desktop-hd) {
    margin-right: 35px;

    > ul > li { padding: 0 20px; }
  }
}



.nav--secondary {
  .nav__item,
  .nav__item.is-closed {
    > .subnav {
      display: none;
    }
  }

  .nav__item:hover,
  .nav__item.is-open {
    .icn {
      transform: rotate(-180deg);
    }

    > .subnav {
      display: block;
    }
  }



  .icn {
    width: auto;
    height: auto;
    margin-left: rem(10);
  }

  .has-children {
    display: inline-flex;
    align-items: center;
  }

  .subnav {
    position: absolute;
    background-color: $white;
    display: block;
    font-size: rem(14);
    font-weight: 400;
    top: rem(40);
    padding: rem(20);
    box-shadow: 0 5px 10px rgba(#000, .15);
    text-align: center;

    @include between($tablet, $desktop) {
      color: $blue;
    }

    li:hover {
      color: $red;
    }
  }

  .subnav__item + .subnav__item {
    margin-top: rem(10);
  }


}
