.grid.grid--news {

  @include above($tablet) {

  }

  @include above($desktop) {
    display: grid;
    grid-template-columns: 33.333% 33.333% 33.333%;
    grid-row-gap: 20px;
  }

  .col {
    &:nth-child(6n + 1) {
      @include above($tablet) {
      }
      @include above($desktop) {
        grid-column: 1 / span 1;
        grid-row: auto / span 2;
      }
    }

    &:nth-child(6n + 2) {
      @include above($tablet) {
      }
      @include above($desktop) {
        grid-column: 2 / span 2;
        grid-row: auto / span 1;
      }
    }

    &:nth-child(6n + 3) {
      @include above($tablet) {
      }
      @include above($desktop) {
        grid-column: 2 / span 2;
        grid-row: auto / span 1;
      }
    }

    &:nth-child(6n + 4) {
      @include above($tablet) {
      }
      @include above($desktop) {
        grid-column: 1 / span 2;
        grid-row: auto / span 1;
      }
    }

    &:nth-child(6n + 5) {
      @include above($tablet) {
      }
      @include above($desktop) {
        grid-column: 1 / span 2;
        grid-row: auto / span 1;
      }
    }

    &:nth-child(6n + 6) {
      @include above($tablet) {
      }
      @include above($desktop) {
        grid-column: 3 / span 1;
        grid-row: auto / span 2;
      }
    }
  }
}
