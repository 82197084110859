.grid.grid--partners {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .col {
    margin: 5px;
    width: auto;

    @include above($tablet){
      margin: 10px;
    }

    @include above($desktop){
      margin: 20px;
    }
  }

}
