.workshop-small {
  display: flex;
  align-items: stretch;
  height: 100%;

  &__visual-wrapper {
    width: 50%;
    display: none;

    @include above($desktop) {
      display: block;
    }
  }

  &__visual {
    margin: 0;
    width: 100%;
    font-size: rem(12);
    position: relative;

    &:before {
      display: block;
      padding-top: ((140 / 210) * 100%);
      width: 100%;
      content: ' ';
    }

    a {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    img {
      display: block;
      margin: 0;
      width: 100%;
      height: auto;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;


    @include above($desktop) {
      padding-left: 20px;
      width: 50%;
    }

    a {
      align-self: flex-end;
    }
  }

  &__title {
    $fs: 18;
    font-family: $base-font-family;
    font-size: rem($fs);
    font-weight: 900;
    letter-spacing: ls(2.2, $fs);
    margin: 0;
    color: $red;
    text-transform: uppercase;
  }

  &__description {
    $fs: 15;
    font-family: $title-font-family;
    font-size: rem($fs);
    font-weight: 300;
    line-height: lh(24, 16);
    margin: 0;
    flex-grow: 1;
    color: $darkblue;
  }
}

.block--workshop {
  .block__visual {
    overflow: hidden;

    img {
      transition: transform .3s ease-in-out;
    }
  }
}
