.block.block--education {
  @include clearfix();
  position: relative;
  margin: 20px 0;

  @include above($tablet-xs) {
    display: block;
    margin: 0;
  }

  > a {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;

    &:hover + .block__visual {
      img {
        transform: scale(1.08);
      }

      + .block__content {
        .btn {
          color: $white;
          background-color: $darkblue;

          .icn,
          .btn__text + .icn {
            margin: 0 !important;
          }
        }
      }
    }
  }

  .block__visual {
    width: 125px;
    margin: 0;
    position: absolute;
    top: 50%;
    overflow: hidden;
    transform: translateY(-50%);

    @include above($tablet-xs) {
      position: relative;
      transform: none;
      top: auto;
      margin: 0 15px;
      width: auto;
      height: auto;
    }

    img {
      transition: all .3s ease-in-out;
    }
  }

  .block__content {
    margin-top: 0;
    width: calc(100% - 115px);
    float: right;

    @include above($tablet-xs) {
      position: relative;
      margin-top: -30px;
      width: auto;
      float: none;
    }

    .info-block {
      padding: 20px 45px 20px 25px;
    }
    .info-block__button {
      margin: -20px -45px -20px 0;
    }
  }
}

@include below($tablet-xs) {
  .col:nth-child(even) .block.block--education {
    .block__visual {
      margin-left: 10px;
    }
    .block__content .info-block {
      padding-left: 35px;
    }
    .block__content .info-block::before {
      display: block;
      width: 20px;
      left: -115px;
    }
  }
}
