.block--lesson-package {
  .block__visual {
    margin: auto;
    height: 40px;
    margin-bottom: -10px;
    text-align: center;
    background-color: transparent;

    img {
      display: inline-block;
      box-shadow: none;
      height: 100%;
      width: auto;
      transition: transform .3s ease-in-out;
    }
  }

  .info-block {
    $padding: 35px;

    padding: $padding;

    &__title {
      $fs: 18;
      font-size: rem($fs);
      font-weight: 700;
      letter-spacing: ls(2.2, $fs);
      margin-bottom: 10px;
    }

    &__button {
      margin: -$padding;
      margin-top: 0;
    }

  }
}
