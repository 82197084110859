$base-margin: 20px;
$base-margin-lrg: 40px;

.constricted {
  max-width: 1440px;
  margin: auto;
  position: relative;
  padding: 0 $base-margin;

  @include above($tablet) {
    padding: 0 $base-margin-lrg;
  }
}

.grid {
  @include above($tablet-xs) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.grid .col {
  position: relative;
  margin: 0;
  width: 100%;
}
