.article {

  &__inner {
    margin-top: rem(40);

    @include above($desktop) {
      margin-top: rem(60);
    }
  }

  &__content,
  &__media {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }

  &__sidebar {
    margin-bottom: 40px;
  }
}

.article__content {

  figure {
    margin: 0;
    width: 100%;
    display: block;
  }

  img {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 20px);
    margin: 0;
    height: auto;
    margin-top: 40px;
    margin-bottom: 40px;

    &:nth-child(2n + 2) {
      margin-left: 20px;
    }

    &:first-of-type:last-of-type {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}


.article__media__item {
  position: relative;
  width: 100%;

  .article__media__item + .article__media__item {
    margin-top: 40px;
  }

  & > iframe {
    display: block;
    margin: 0;
  }

  &.vimeo,
  &.youtube,
  &.soundcloud {
    &:before {
      display: block;
      width: 100%;
      content: ' ';
    }

    & > iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }


  &.soundcloud {
    &:before {
      padding-top: ((150 / 745) * 100%);
    }
  }

  &.vimeo,
  &.youtube {
    &:before {
      padding-top: ((315 / 560) * 100%);
    }
  }
}


.article.has-sidebar {
  .article__inner {
    display: flex;
    flex-direction: column;


    @include above($tablet) {
      display: grid;
      grid-template-columns: 56.666% 10% 33.333%;
      grid-template-rows: auto;
    }

    @include above($desktop) {
      grid-template-columns: 56.666% 10% 33.333%;
    }

  }

  .article__content,
  .article__media {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    order: 2;

    @include above($tablet) {
      grid-column: 1 / span 1;
    }
  }

  .article__sidebar {
    order: 1;

    @include above($tablet) {
      grid-column: 3 / span 3;
      grid-row: 1 / 1;
    }
  }

  .article__related {
    order: 99;
    @include above($tablet) {
      grid-column: 1 / span 3;
    }
  }
}


.article__content {
  p:first-child {
    margin-top: 0;
  }
}

.article__related {
  margin-top: 60px;
}

.article__content + .article__related {
  margin-top: 120px;
}



.article__header {
  color: $blue;

  @include above($desktop) {

    color: white;
    top: -274px;
    position: relative;

    &__inner {
      left: 60%;
      right: 0;
      position: absolute;
    }
  }

}


.article__header__title {
  $fs: 14;
  font-size: rem($fs);
  font-weight: 900;
  line-height: lh(24, $fs);
  letter-spacing: ls(1.75, $fs);
  text-transform: uppercase;
  margin: 20px 0 0 0;

  @include above($tablet) {
    $fs: 18;
    font-size: rem($fs);
    line-height: lh(24, $fs);
    letter-spacing: ls(1.75, $fs);
    margin: 20px 0 0 0;
  }

  @include above($desktop) {
    $fs: 21;
    font-size: rem($fs);
    line-height: lh(24, $fs);
    letter-spacing: ls(1.75, $fs);
    margin: 50px 0 0;
  }
}

.article__header .date-list {
  margin: 1em 0;
  &__item {
    display: inline-block;
  }

  li:last-child span{
    display: none;
  }
}

.article__header .tags {
  display: none;

  @include above($desktop) {
    display: block;
  }
}
