.map {
  width: auto;
  height: 355px;
  background: #eee;
  margin: 0 -20px;

  @include above($tablet) {
    order: 1;
    margin: 0;
    width: 525px;
    height: 355px;
  }
}

.contact {
  margin-bottom: -($main-bottom-padding);
}


.contact__details {
  margin: rem(20) 0;
  width: 100%;

  @include above($tablet) {
    margin: rem(50) 0 rem(100) 0;
    display: flex;
    justify-items: stretch;
  }

  .info-block {
    margin: 0 -20px;

    @include above($tablet) {
      margin: -10px 0 -10px -30px;
      padding-left: 60px;
      padding-right: 40px;
      height: calc(100% + 20px);
    }

    h4 {
      margin: 0;
    }

    p {
      margin: 10px 0;
    }
  }
}

.contact__content {
  @include above($tablet) {
    order: 2;
  }
}

.contact__hours {
  margin-top: 2em;

  table {
    margin: 10px 0;

    th, td {
      vertical-align: top;
    }
  }
}


.contact__form {
  .title {
    @include below($tablet) {
      position: relative;
      &::before {
        height: auto;
        width: 100vw;
        transform: none;
        left: -20px;
        bottom: -10px;
      }
    }
  }
}

.contact-form-container.hide,
.thankyou-container.hide {
  display: none;
}

.thankyou-container {
  padding: 100px 0;
}
