.mega-menu-wrapper {
  background-color: #F5F5F5;
  overflow: hidden;
  transition-property: transform, box-shadow, opacity;
  transition-duration: .3s;
  transition-delay: 0s;
  transition-timing-function: ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  opacity: 0;
  transform: translateY(-110%);
  box-shadow: 0 3px 20px rgba(#000, 0);
  display: none;

  &.is-open {
    display: block;
    opacity: 1;
    transform: translateY(0);
    box-shadow: 0 3px 20px rgba(#000, .1);
    
  }

  @include above($tablet) {
    top: 123px;
    display: none;
  }

  @include above($desktop) {
    top: 83px;
  }
}

.nav--mega {
  padding: 20px 0;
}

