.grid--team {
  $gutter: 80px;

  max-width: 1125px;
  margin: auto;
  justify-content: space-between;

  .col {
    margin-bottom: 50px;

    @include above($tablet-xs) {
      $gutter: 30px;
      width: calc(50% - #{($gutter)});
      margin-bottom: 30px;
    }


    @include above($desktop-sm) {
      $gutter: 50px;
      width: calc(33.333% - #{($gutter)});
    }

    @include above($desktop) {
      $gutter: 80px;
      width: calc(33.333% - #{($gutter)});
    }

  }
}
