.page--agenda {
  position: relative;
  margin-bottom: 60px;
}

.grid--agenda {
  .block--event {
    .block__visual {
      overflow: hidden;
      img {
        transition: transform .3s ease-in-out;
      }
    }
  }
}
