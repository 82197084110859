
.grid.grid--for-rent {
  margin-bottom: -$main-bottom-padding;
  display: block;

  @include above($tablet-xs) {
    display: flex;

    .col {
      width: 100%;
      margin: 1px 0;
    }
  }

  @include above($desktop-sm) {
    .col {
      width: calc(50% - 2px);
      margin: 1px;
    }
  }
}
