// ==========================================================================
// Base
// Base rules are the defaults. They are almost exclusively single element
// selectors but it could include attribute selectors, pseudo-class selectors,
// child selectors or sibling selectors. Essentially, a base style says that
// wherever this element is on the page, it should look like this. ==========================================================================

body {
  left: 0;
  position: relative;
  max-width: 100vw;
  transition: left .3s ease-in-out;
  overflow-x: hidden;
}

* {
  outline: none;
}

button {
  padding: 0;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  a { text-decoration: none; }
}

h4, h5, h6 {
  font-size: inherit;
}

$main-bottom-padding: 60px;

.main {
  position: relative;
  overflow: hidden;
  padding-bottom: $main-bottom-padding;
}


.intro {
  max-width: 700px;
  margin-top: 0;
  margin-bottom: 60px;

  p {
    margin: 0;
  }

  p + p {
    margin-top: 1em;
  }
}

.intro--columns {

  @include above($tablet) {
    max-width: 100%;
    display: flex;
    justify-content: space-between;

    p {
      width: 46%;
    }

    p + p {
      margin-top: 0;
    }
  }

}


a {
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: inherit;
}


nav {
  ol,
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}


a.link-arrow {
  position: relative;

  .icn {
    @include size(20px);
    position: absolute;
    right: -20px;
    top: 50%;
    margin-top: -8px;

    svg {
      display: block;
      width: 100%;
      height: auto;

      fill: currentColor;
    }
  }
}

.search-results,
.site-footer__menu,
.site-footer__address,
.article.single {

  a {
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      right: 50%;
      bottom: 0;
      height: 1px;
      opacity: 0;
      background-color: currentColor;
      transition-property: opacity, left, right;
      transition-duration: .3s;
      transition-timing-function: ease-in-out;
    }

    &:hover {
      &::after {
        opacity: 1;
        left: 0;
        right: 0;
      }
    }
  }

  .is-active a::after {
    opacity: 1;
    left: 0;
    right: 0;
  }

}

.site-footer__address {
  .site-footer__tel,
  .site-footer__email {
    &::after {
      opacity: 1;
      left: 0;
      right: 0;
    }
  }
}

.fact__visual,
.workshop-small__visual,
.room__visual,
.block__visual,
.hero-banner__visual,
.hero__visual {
  background-color: $blue;
  a { display: block; }

  img {
    filter: grayscale(100%);
    z-index: 0;
    mix-blend-mode: screen;
  }

}

.block {
  > a {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 4;

    &:hover + .block__visual {
      img {
        transform: scale(1.08);
      }

      + .block__content, + .info-block {
        .btn {
          color: $white;
          background-color: $darkblue;

          .icn,
          .btn__text + .icn {
            margin: 0 !important;
          }
        }
      }
    }
  }
}
