
.block--news {
  position: relative;
  margin-bottom: 30px;
  @include above($tablet) {
    margin: auto;
  }

  > a {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;

    &:hover + .block__visual {
      img {
        transform: translate(-50%, -50%) scale(1.08);
      }

      + .block__content {
        .btn {
          color: $white;
          background-color: $darkblue;

          .icn,
          .btn__text + .icn {
            margin: 0 !important;
          }
        }
      }
    }
  }

  .block__visual {
    overflow: hidden;
    position: relative;

    &:before {
      display: block;
      width: 100%;
      content: ' ';
      padding-top: ((88 / 296) * 100%);

      @include above($tablet) {
        padding-top: ((215 / 340) * 100%);
      }

      @include above($desktop) {
        padding-top: ((440 / 340) * 100%);
      }
    }

    img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      transition: all .3s ease-in-out;

      @include above($desktop) {
        width: auto;
        height: 100%;
      }
    }

    a {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .info-block__title {
    $fs: 18;
    font-size: rem($fs);
    line-height: lh(24, $fs);
    letter-spacing: ls(1.75, $fs);
  }
}

@include above($tablet) {

  .grid.grid--news .col {
    &:nth-child(6n + 1),
    &:nth-child(6n + 6) {
      .block {
        max-width: 370px;
        margin-bottom: 30px;
      }
    }

    &:nth-child(6n + 2),
    &:nth-child(6n + 3),
    &:nth-child(6n + 4),
    &:nth-child(6n + 5) {
      margin-bottom: 30px;
    }

    &:nth-child(6n + 6) {
      display: flex;
      justify-content: flex-end;

      .block {
        width: 100%;
      }
    }

    &:nth-child(6n + 2) {
      .block {
        z-index: 2;
      }
    }

    &:nth-child(6n + 2),
    &:nth-child(6n + 3),
    &:nth-child(6n + 4),
    &:nth-child(6n + 5) {
      .block {
        display: flex;
      }

      .block__content {
        margin: 0;
        flex-grow: 1;
        min-width: 280px;
      }

      .block__visual {
        width: 540px;
        margin: 0;

        &:before {
          display: block;
          padding-top: ((255 / 540) * 100%);
          width: 100%;
          content: ' ';
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      .info-block {
        padding-top: 0;
      }
    }

    &:nth-child(6n + 2),
    &:nth-child(6n + 4),
    &:nth-child(6n + 5) {
      .info-block {
        color: $blue;
        border-bottom: 1px solid $red;

        &::before {
          display: none;
        }
      }
      .info-block__title {
        &, a { color: $red; }
      }

      .btn {
        background-color: $red;
        color: white;
        &:hover {
          background-color: $blue;
        }
      }
    }

    &:nth-child(6n + 3) {
      .info-block::before {
        top: -40px;
        bottom: 0;
        left: -80px;
        width: 100vw;
        height: auto;
        transform: skew(45deg);
      }
    }
  }
}

@include above($desktop) {

  .grid.grid--news .col {
    &:nth-child(6n + 2),
    &:nth-child(6n + 3) {
      padding-left: 30px;
      margin: 0;
    }

    &:nth-child(6n + 4),
    &:nth-child(6n + 5) {
      padding-right: 30px;
      margin: 0;
    }

    &:nth-child(6n + 2) {
      .block {
        margin-top: 60px;
        z-index: 2;
      }
    }

    &:nth-child(6n + 6),
    &:nth-child(6n + 3) {
      .block {
        margin-bottom: 60px;
      }
    }
  }
}
