.block.block--teammember {
  @include below($tablet) {
    display: flex;
    align-items: center;

    .block__visual {
      margin: 0;
      width: 125px;
    }

    .block__content {
      margin: 0;
      flex-grow: 1;
      width: auto;
      .info-block {
        &::before {
          width: auto;
          left: -10px;
          right: 0;
        }
      }
    }
  }
}
