.search-results {
  color: $blue;
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    display: flex;
    align-items: center;

    > .icn > svg {
      fill: $red;
    }
  }
}
