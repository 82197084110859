.icon-list {
  margin: 0;
  list-style: none;
  padding: 0;

  li {
    display: flex;
    align-items: top;

    span {
      padding: .25em 0;
    }

    > .icn {
      margin-right: 10px;
      svg {
        fill: currentColor;
      }
    }
  }
}
