.grid--workshops {
  max-width: 1125px;
  margin: auto;
  justify-content: space-between;


  .col {
    $gutter: 30px;
    margin-bottom: $gutter;

    @include above($tablet-xs) {
      $gutter: 30px;
      width: calc(50% - #{($gutter)});
      margin-bottom: $gutter;
    }

    @include above($desktop-sm) {
      $gutter: 66px;
      width: calc(33.333% - #{($gutter)});
      margin-bottom: $gutter;
    }

    @include above($tablet) {
      &:nth-child(6n+2) {
        z-index: 3;
        figure {
          position: relative;
          &::after {
            @include lines-horizontal($red);
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -45px;
            width: 100vw;
            height: 90px;
            transform: translateX(100%);
          }
        }
      }

      &:nth-child(6n+3) {
        z-index: 2;
        figure {
          &::before {
            z-index: 2;
            content: '';
            display: block;
            width: 100vw;
            height: 150px;
            background: $red;
            mix-blend-mode: multiply;
            position: absolute;
            margin-left: 76px;
            transform: translateX(-100%) skew(-45deg);
          }
        }
      }
    }

    @include above($desktop-sm) {
      &:nth-child(6n+3),
      &:nth-child(6n+2) {
        figure::before,
        figure::after {
          display: none;
        }
      }

      &:nth-child(6n+3) {
        z-index: 3;
        figure {
          position: relative;
          &::after {
            @include lines-horizontal($red);
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -45px;
            width: 100vw;
            height: 90px;
            transform: translateX(100%);
          }
        }
      }
    }

    @include above($desktop-sm) {
      &:nth-child(6n+4) {
        z-index: 2;
        figure {
          &::before {
            z-index: 2;
            content: '';
            display: block;
            width: 100vw;
            height: 150px;
            background: $red;
            mix-blend-mode: multiply;
            position: absolute;
            margin-left: 76px;
            transform: translateX(-100%) skew(-45deg);
          }
        }
      }
    }
  }
}
