.block {
  position: relative;

  &__visual {
    margin: 0 15px;
    min-height: 80px;

    img {
      display: block;
      width: 100%;
      height: auto;
      // box-shadow: 0 0 7px rgba(#000, .12);
    }
  }

  &__content {
    position: relative;
    margin-top: -15px;

    @include above($desktop) {
      margin-top: -30px;
    }
  }

  &__title {
    $fs: 21;
    font-family: $title-font-family;
    font-size: rem($fs);
    font-weight: 900;
    line-height: lh(24, $fs);
    letter-spacing: ls(1.75, $fs);
    text-transform: uppercase;
    margin: 0;
    color: $white;
  }

  &__postdate,
  &__date {
    margin: 0;
    color: $white;
  }
}



.block-meta {
  height: 50px;
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  overflow: hidden;

  .block-meta__logo-bar {
    position: absolute;
    height: 34px;
    padding:  5px 15px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: -20px;
      background-color: $white;
      transform: skew(-45deg);
      box-shadow: 0 2px 4px rgba(#000, .05);
    }
  }

  .media-icon {
    @include size(35px);
    position: absolute;
    right: 0;
    top: 10px;
    color: $blue;
    background-color: white;
    // box-shadow: 0 2px 4px rgba(#000, .05);

    .icn svg {
      fill: $blue;
    }
  }

  .meta-organisator {
    opacity: 1;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    background: white;
    line-height: 0;
    padding: 7px 10px;

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 32px 32px 0 0;
      border-color: #ffffff transparent transparent transparent;
      position: absolute;
      left: 100%;
      top: 0;
    }



  }

  .event-logos {
    position: relative;
    z-index: 2;

    img {
      display: inline-block;
      margin: 0;
      width: auto;
      height: 24px;
    }
  }
}
