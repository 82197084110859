$primary-color: $red;
$secondary-color: $blue;

.btn {
  $text-color: $primary-color;
  $fs: 16;

  font-family: $title-font-family;
  font-size: rem(16);
  font-weight: 600;
  text-decoration: none;
  transition: color .3s ease-in-out;
  height: 40px;
  padding: 0 rem(10) 0 rem(15);
  background-color: $white;
  color: $text-color;
  transition-property: background-color, color;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
  transition-delay: 0s;
  display: inline-flex;
  align-items: center;

  & + & { margin-left: 10px; }

  &__text {
    display: inline-block;
    margin-right: rem(5);
  }

  .icn {
    margin-left: 0;
    width: 0;
    overflow: hidden;
    opacity: 0;
    transition-property: opacity, margin, width;
    transition-timing-function: ease-in-out;
    transition-duration: .3s, .3s, .3s;
    transition-delay: 0s, .15s, .15s;

    svg {
      transition: fill .3s ease-in-out;
      fill: currentColor;
    }
  }

  @include btn-hover() {
    .icn {
      width: 35px;
      opacity: 1;
      transition-delay: .15s, 0s, 0s;
    }

    .btn__text + .icn {
      margin-left: rem(-5);
    }
  }

}


.btn--primary {
  $text-color: $white;

  @include notch-top-left(14px);
  padding: 0 rem(10) 0 rem(20);
  background-color: $primary-color;
  color: $text-color;

  .btn__text {
    margin-right: rem(5);
  }

 @include btn-hover() {
    background-color: $secondary-color;

    .icn {
      margin-left: rem(-15);
    }

    .btn__text + .icn {
      margin-left: rem(-5);
    }
  }
}


.btn--secondary {
  $text-color: $white;
  color: $text-color;

  @include notch-top-left(14px);
  background-color: transparent;
  border-right: 2px solid $white;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 40px;
    background-repeat: no-repeat;
    background-size: auto 100%;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    transition-delay: 0s;
  }

  &::before {
    background-image: url('../img/ui/btn-white-outline.svg');
  }

  .btn__text {
    position: relative;
    z-index: 2;
  }

  .icn {
    position: relative;
    z-index: 2;
  }


  @include btn-hover(){
    color: $blue;
    background-color: $white;

    .icn {
      margin-left: rem(-15);
    }

    .icn svg { fill: $blue }

    &::after {
      opacity: 1;
    }
  }
}

.btn--small {
  display: inline-block;
  width: 35px;
  height: 35px;
  position: relative;
  overflow: hidden;
  position: relative;
  clip-path: none;
  // color: $red;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
  }

  .icn {
    position: absolute;
    opacity: 1;
    width: 35px;
    top: 0;
    left: 0;

    svg {
      fill: currentColor;
    }
  }

  @include btn-hover() {
    color: $white;
    background-color: $secondary-color;

    .icn,
    .btn__text + .icn {
      margin: 0 !important;
    }
  }
}

.btn--small.btn--secondary {
  border: 2px solid white;

  &::before,
  &::after {
    display: none;
    background-image: none;
  }

  &::before {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  .icn {
    width: 31px;
    height: 31px;
  }

  @include btn-hover() {
    background-color: $white;

    .icn {
      width: 31px;
      height: 31px;
    }
  }
}


.btn--invert {
  color: $blue;

  @include btn-hover() {
    background-color: $red;
    color: $white;
  }
}


.btn--clear {
  background-color: transparent;
  color: inherit;

  @include btn-hover() {
    background-color: transparent;
    color: inherit;
  }
}

.btn[disabled] {
  opacity: .4;
  cursor: not-allowed;
}
