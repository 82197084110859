.msg {
  display: block;
  padding: 5px;
  margin-top: -2px;

  &--error {
    display: none;
    background-color: $error-color;

    .error & {
      display: block;
    }
  }
}