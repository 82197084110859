.grid--lesson-packages {
  $gutter: 76px;
  max-width: 1220px;
  margin: auto;
  justify-content: space-between;

  .col {
    margin-bottom: $gutter;

    .block__visual {
      position: relative;
      z-index: 2;
      mix-blend-mode: multiply;
    }

    &:nth-child(6n+1) {
      &::after {
        @include lines-horizontal($red);
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -45px;
        width: 100vw;
        height: 90px;
        transform: translateX(100%);
        z-index: -1;
      }
    }

    @include above($tablet-xs) {
      width: calc(50% - #{($gutter/2)});
    }

    @include above($desktop-sm) {
      width: calc(33.333% - #{($gutter)});
    }
  }
}
