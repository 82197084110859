
.block--lesson {

  @include above($desktop) {
    display: flex;
    flex-wrap: nowrap;
  }

  &::before {
    content: '';
    display: block;
    background-color: $red;
    width: 19px;
    position: absolute;
    top: -10px;
    left: -10px;
    bottom: 0;
    z-index: 2;
    mix-blend-mode: multiply;

    @include above($desktop) {
      display: none;
    }
  }

  > .block__header {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    figure {
      order: 1;
    }

    .info-block__title {
      padding: 0 0 0 20px;
      width: calc(100% - 200px);
      word-break: break-all;
      order: 2;

      @include above($tablet-xs) {
        width: auto;
        word-break: normal;
      }
    }

    > .icn {
      right: -10px;
      position: relative;
      transform: scaleY(-1);
      order: 99;

      > svg {
        fill: $red;
      }
      @include above($tablet-xs) {
        right: auto;
      }
    }

    @include above($desktop) {
      display: block;
      cursor: default;
    }
  }

  &.is-closed {
    .block__header .icn {
      transform: scaleY(1);
    }
  }

  figure {
    width: 160px;
    margin: 0;

    @include above($desktop) {
      margin: 10px 0 0 0;
      width: 310px;
    }
  }

  .info-block {
    // width: calc(100% - 160px);
    padding: 10px 10px 0 20px;
    color: $blue;
    margin: 0;
    overflow: hidden;
    transition: height .3s ease-in-out;

    @include above($desktop) {
      width: calc(100% - 310px);
      margin: 0 0 0 -12px;
      padding: 10px 0 0 45px;
      overflow: visible;
    }

    &__title {
      font-size: rem(18);
      font-weight: 700;
      color: $red;

      &.is-hidden {
        display: none;
      }
    }

    .icon-list {
      margin: 1em 0;
    }

    .icon-list .icn svg { fill: currentColor; }

    &::before {
      display: none;

      @include above($desktop) {
        display: block;
        width: 24px;
      }
    }
  }
}



@include above($desktop) {
  .grid.grid--lessons {
    .col {
      // Block 1 & 6
      &:nth-child(6n + 1),
      &:nth-child(6n + 6) {
        .block--lesson {
          flex-direction: column;

          .info-block {
            width: 100%;
            margin: 0 0 0 0;

            &::before {
              top: -25px;
              bottom: 0;
              height: auto;
            }
          }
        }
      }
    }
  }
}

