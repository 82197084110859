@include below($desktop-sm) {

  .events-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .filter-wrapper {
    position: fixed;
    top: 0;
    left: 100vw;
    bottom: 0;
    width: 100vw;
    z-index: 99;
    overflow: scroll;
    transition: left .3s ease-in-out;

    &__inner {
      background-color: $blue;
      padding: 30px;
    }
  }

  [data-mobile-filters="close"] {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  [data-mobile-filters="close"] {
    display: block;
  }

  .filter-tag[data-mobile-filters="open"] {
    display: inline-flex;
  }

  .filter-button-list {
    max-width: 350px;
    margin: auto;

    > li {
      margin-bottom: 40px;
    }
  }

  .filter-buttons {
    text-align: center;
    max-width: 350px;
    margin: auto;
  }

  .filter-tag {
    display: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  h3 {
    margin: 0 0 1em 0;
  }

  &,
  h3,
  .custom-checkbox label,
  .ui-datepicker-header,
  .ui-datepicker-calendar {
    color: white;
  }

  body.show-filters {
    left: -100vw;

    .filter-wrapper  {
      left: 0;
    }
  }
}




@include above($desktop-sm) {

  .events-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  [data-mobile-filters="close"],
  [data-mobile-filters="open"],
  .filter-buttons {
    display: none;
  }

  .filter-wrapper {

  }

  .filter-button-list {
    list-style: none;
    padding: 0;
    margin: 0 0 0 50px;
    display: flex;

    &__item + &__item {
      margin-left: 20px;
    }
  }


  .filter-list {
    padding: 0;
    margin: 0;
    list-style: none;

    &__item + &__item {
      margin-top: 5px;
    }
  }

  .filters {
    position: absolute;
    z-index: 9;
    margin-top: 20px;
    user-select: none;
    display: none;

    h3 {
      display: none;
    }

    &.is-shown {
      display: block;
    }

    &__inner {
      padding: 20px 25px;
      background-color: white;
      box-shadow: 0 0 10px rgba(black, .15);
    }
  }

}

.filter {
  $text-color: $blue;
  $fs: 16;

  @include notch-top-left-bottom-right(14px);
  font-family: $title-font-family;
  font-size: rem(16);
  font-weight: 600;
  text-decoration: none;
  transition: color .3s ease-in-out;
  height: 40px;
  padding: 0 rem(5) 0 rem(20);
  background-color: $grey;
  color: $text-color;
  transition-property: background-color, color;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
  transition-delay: 0s;
  display: inline-flex;
  align-items: center;

  & + .filter-tag,
  & + & { margin-left: 10px; }

  &__text {
    display: inline-block;
    margin-right: rem(5);
  }

  .icn {
    transition-property: transform;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    transition-delay: 0s;
    margin-left: rem(-5);

    svg {
      transition-property: fill, transform;
      transition-timing-function: ease-in-out;
      transition-duration: .3s;
      transition-delay: 0s;
      fill: $text-color;
    }
  }

  &:hover,
  &:focus {
    background-color: $blue;
    color: $white;

    .icn {
      transform: rotate(-180deg);
    }
    .icn svg {
      fill: $white;
    }
  }

}



.filter-tag {
  $text-color: $blue;
  $fs: 16;

  @include notch-top-left-bottom-right(14px);
  font-family: $title-font-family;
  font-size: rem(16);
  font-weight: 600;
  text-decoration: none;
  transition: color .3s ease-in-out;
  height: 40px;
  padding: 0 rem(20);
  // background-color: $grey;
  color: $text-color;
  transition-property: background-color, color;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
  transition-delay: 0s;
  display: inline-flex;
  align-items: center;
  position: relative;

  & + & { margin-left: 10px; }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    width: 20px;
    z-index: 0;
    background-size: auto 40px;
    background-repeat: no-repeat;
    background-image: url('../img/ui/btn-blue-outline.svg');
  }

  &::before {
    left: 0;
    width: 100%;
  }

  &::after {
    right: 0;
    transform: scale(-1);
  }

  &__text {
    display: inline-block;
    position: relative;
    z-index: 2;
  }

  .icn {
    transition-property: transform;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    transition-delay: 0s;
    margin-left: rem(-5);

    svg {
      transition-property: fill, transform;
      transition-timing-function: ease-in-out;
      transition-duration: .3s;
      transition-delay: 0s;
      fill: currentColor;
    }
  }

  &.is-selected,
  &:hover,
  &:focus {
    background-color: $blue;
    color: $white;
  }

}

.daterangepicker.ltr.show-calendar.opensright {
  border-radius: 0;
  margin-top: 20px;
  border: 0;
  padding: 20px 25px;
  background-color: white;
  box-shadow: 0 0 10px rgba(black, .15);

  &:before,
  &:after {
    display: none;
  }

  td.active, td.active:hover {
    background: $lightblue;
  }
}
