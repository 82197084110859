

.section--education {
  overflow: hidden;
  padding: 20px 0 60px;


  @include above($tablet-xs) {
    padding: 40px 0 80px;

    .block__visual {
      position: relative;
    }

    .block:nth-child(even) {
      position: relative;
      &::before {
        content:'';
        display: block;
        position: absolute;
        background-color: $red;
        mix-blend-mode: multiply;
        top: -40px;
        width: 100%;
        height: 128px;
        z-index: 1;
      }
    }

    .block:nth-child(1) .block__visual::before,
    .block:nth-child(3) .block__visual::after {
      content: '';
      position: absolute;
      display: block;
      width: 100vw;
      height: 90px;
      z-index: -1;
      top: calc(50% - 45px);
      @include lines-horizontal($red);
    }

    .block:nth-child(1) .block__visual::before {
      transform: translateX(-100%);
    }
  }

  @include above($desktop) {
    padding: 90px 0 160px;
  }
}



.section--events {

  $bar-height: 65px;
  $bar-offset: 110px;

  // min-height: 600px;
  padding-bottom: 40px;

  &::after {
    content:'';
    background-color: $white;
    display: block;
    position: absolute;
    width: 50%;
    height: $bar-height;
    bottom: -50px;
    right: -($bar-offset * 2);
    transform: skew(-45deg);

    @include above($tablet) {
      bottom: 0;
    }

  }

  .section__button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}




.section--for-rent {
  padding: 20px 0;
  text-align: center;

  @include above($tablet-xs) {
    padding: 40px 0;
  }

  @include above($desktop) {
    padding: 80px 0;
  }

  &__content {
    max-width: 610px;
    margin: 50px auto 0;
    padding: 0 20px;
    color: $darkblue;
    line-height: rem(24px);

    h4 {
      color: $red;
      margin: 0;
    }

    p {
      margin: 0;
      strong {
        color: $red;
      }
    }

    .btn {
      margin-top: 50px;
    }
  }

}





.section--news {
  .background-bar {
    // position: relative;
    // transform: translateY(0);
    // top: auto;
    // margin-top: 0;


    @include above($desktop) {
      position: relative;
      transform: translateY(0);
      top: auto;
      margin-top: 0;
    }
  }
}

