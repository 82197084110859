
.grid--nav-education {
  margin: auto;
  justify-content: space-between;

  .col {
    $gutter: 40px;
    width: calc(33.333% - #{($gutter)});
  }
}
