.room {
  width: 100%;
  position: relative;

  > a {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;

    &:hover + .room__visual {
      img {
        transform: scale(1.08);
      }

      + .room__content {
        .btn {
          color: $white;
          background-color: $darkblue;

          .icn,
          .btn__text + .icn {
            margin: 0 !important;
          }
        }
      }
    }
  }

  &__visual {
    width: 100%;
    margin: 0;
    z-index: 0;
    position: relative;
    overflow: hidden;

    &:before {
      display: block;
      width: 100%;
      content: ' ';
      padding-top: ((490 / 750) * 100%);
    }

    img {
      width: 100%;
      height: auto;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) scale(1);
      transition: all .5s ease-in-out;

      @include above($desktop-sm) {
        position: relative;
        top: auto;
        transform: none;
      }
    }

    @include above($desktop-sm) {
      height: auto;

      &::before {
        display: none;
      }
    }
  }

  &__content {
    position: relative;
    top: -50px;
    left: 50%;
    width: 300px;
    margin-left: -150px;

    @include above($tablet-xs) {
      position: absolute;
      bottom: 30px;
      top: auto;
    }

    &::before {
      content: '';
      position: absolute;
      display: block;
      background-color: $red;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      mix-blend-mode: multiply;
    }
  }

  &__widget {
    color: $white;
    z-index: 2;
    position: relative;

    &__content,
    &__title {
      margin: 0;
      padding: 10px 35px 10px 15px;
    }

    &__title {
      $fs: 20px;
      font-size: rem($fs);
      text-transform: uppercase;

      a { color: currentColor; }
    }

    &__content {
      background-color: $red;
    }

    .btn--small {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
