.background-bar {
  height: 60px;
  background-image: url('../img/bar.svg');
  background-size: 800px auto;
  background-position: 70% center;
  background-repeat: no-repeat;

  @include above($tablet-xs) {
    background-size: 120% auto;
    height: 90px;
  }

  @include above($desktop) {
    $height: 65px;

    background: none;
    position: absolute;
    width: 100%;
    height: ($height * 2);
    overflow: hidden;
    top: 50%;
    margin-top: -($height * 2);
    z-index: -1;

    &::before {
      @include lines-vertical($white);
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: calc(50% + 600px);
      height: $height;
      z-index: 9;
    }

    &__top,
    &__bottom {
      display: block;
      position: absolute;
      height: $height;
      background-color: $red;
    }

    &__top {
      width: calc(50% + 550px);
      transform: skew(45deg);
      background-color: $red;
      left: -35px;
      top: 0;
      overflow: hidden;

      &::before,
      &::after {
        content: '';
        display: block;
        height: $height;
        position: absolute;

      }

      &::before {
        width: 110px;
        background-color: $blue;
        right: 0;
        z-index: 1;
      }

      &::after {
        transform: skew(-45deg);
        background-color: $blue;
        width: 380px;
        right: 35px;
        mix-blend-mode: multiply;
        z-index: 0;
      }
    }

    &__bottom {
      z-index: 2;
      top: $height;
      left: calc(50% + 358px);
      right: -35px;
      transform: skew(45deg);

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 110px;
        width: 100%;
        height: 100%;
        background-color: $blue;
        mix-blend-mode: multiply;
        right: 0;
        z-index: 1;
      }
    }
  }
}


.background-bar--wider {

  @include above($desktop) {
    &::before {
      right: calc(50% + 730px);
    }

    .background-bar__top {
      width: calc(50% + 880px);
    }

    .background-bar__bottom {
      left: calc(50% + 690px);
    }
  }

}
