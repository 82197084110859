.info-block {
  padding: 20px;
  color: $white;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $red;
    mix-blend-mode: multiply;
    z-index: 0;
  }

  a:not(.btn) {
    color: inherit;
  }

  &__inner {
    position: relative;
    z-index: 1;
  }

  &__title {
    $fs: 14;
    font-family: $title-font-family;
    font-size: rem($fs);
    font-weight: 900;
    line-height: lh(18, $fs);
    letter-spacing: ls(1.75, $fs);
    text-transform: uppercase;
    margin: 0;
    color: inherit;

    @include above($tablet) {
      $fs: 18;
      font-size: rem($fs);
      line-height: lh(20, $fs);
      letter-spacing: ls(1.75, $fs);
    }

    @include above($desktop) {
      $fs: 21;
      font-size: rem($fs);
      line-height: lh(24, $fs);
      letter-spacing: ls(1.75, $fs);
    }
  }

  &__inner > p,
  &__jobtitle,
  &__description,
  &__date {
    $fs: 12;
    margin: 0;
    font-size: rem($fs);
    line-height: lh(16, $fs);

    @include above($tablet) {
      $fs: 14;
      margin: em(10, $fs) 0;
      font-size: rem($fs);
      line-height: lh(20, $fs);
    }

    @include above($desktop) {
      $fs: 16;
      margin: em(10, $fs) 0;
      font-size: rem($fs);
      line-height: lh(24, $fs);
    }
  }

  &__date {
    span:last-child {
      display: none;
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
  }

  .icon-list .icn svg { fill: $white; }
}

.info-block--blue {
  background-color: $blue;
  &::before {
    display: none;
  }
}

.grid--alternate .col:nth-child(odd) .info-block {
  position: relative;
}

.grid--alternate .col:nth-child(even) .info-block {
  mix-blend-mode: multiply;
  color: $blue;

  &::before {
    display: none;
  }

  .info-block__title {
    color: $red;
  }

  .btn--small {
    color: $white;
    background-color: $red;

    @include btn-hover() {
      background-color: $blue;
    }
  }
}
