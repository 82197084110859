.blue-bg {
  $bar-height: 65px;
  $bar-offset: 40px;

  color: white;
  background-color: $blue;
  position: relative;
  overflow: hidden;

  .title {
    position: relative;
    display: inline-block;
    background: $white;
    margin: 0;

    span {
      position: relative;
      z-index: 1;
    }

    &::before,
    &::after {
      content:'';
      display: block;
      position: absolute;
      background-color: $white;
      width: 100vw;
      z-index: 0;
    }

    &::before {
      height: $bar-height;
      top: -10px;
      left: calc(100% + #{$bar-offset});
      transform: translateX(-100%) skew(-45deg);

      @include above($tablet) {
        top: 0;
      }
    }

    &::after {
      top: 0;
      left: -20px;
      height: 40px;

      @include above($tablet) {
        display: none;
      }
    }
  }
}
