@media screen and (max-width: 1440px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1439px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 1280px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1279px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 1100px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1099px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 990px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 989px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 768px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 767px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 600px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 599px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 480px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 479px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (min-width: 479px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 480px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 599px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 600px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 767px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 768px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 989px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 990px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1099px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1100px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1279px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1280px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1439px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1440px) {
  .foo {
    bar: 'above'; } }

@font-face {
  font-family: "Raleway";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/raleway/raleway-light-webfont.woff2") format("woff2"), url("../fonts/raleway/raleway-light-webfont.woff") format("woff"); }

@font-face {
  font-family: "Raleway";
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/raleway/raleway-lightitalic-webfont.woff2") format("woff2"), url("../fonts/raleway/raleway-lightitalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Raleway";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/raleway/raleway-regular-webfont.woff2") format("woff2"), url("../fonts/raleway/raleway-regular-webfont.woff") format("woff"); }

@font-face {
  font-family: "Raleway";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/raleway/raleway-italic-webfont.woff2") format("woff2"), url("../fonts/raleway/raleway-italic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Raleway";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/raleway/raleway-medium-webfont.woff2") format("woff2"), url("../fonts/raleway/raleway-medium-webfont.woff") format("woff"); }

@font-face {
  font-family: "Raleway";
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/raleway/raleway-mediumitalic-webfont.woff2") format("woff2"), url("../fonts/raleway/raleway-mediumitalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Raleway";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/raleway/raleway-semibold-webfont.woff2") format("woff2"), url("../fonts/raleway/raleway-semibold-webfont.woff") format("woff"); }

@font-face {
  font-family: "Raleway";
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/raleway/raleway-semibolditalic-webfont.woff2") format("woff2"), url("../fonts/raleway/raleway-semibolditalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Raleway";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/raleway/raleway-bold-webfont.woff2") format("woff2"), url("../fonts/raleway/raleway-bold-webfont.woff") format("woff"); }

@font-face {
  font-family: "Raleway";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/raleway/raleway-bolditalic-webfont.woff2") format("woff2"), url("../fonts/raleway/raleway-bolditalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Raleway";
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/raleway/raleway-extrabold-webfont.woff2") format("woff2"), url("../fonts/raleway/raleway-extrabold-webfont.woff") format("woff"); }

@font-face {
  font-family: "Raleway";
  font-weight: 800;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/raleway/raleway-extrabolditalic-webfont.woff2") format("woff2"), url("../fonts/raleway/raleway-extrabolditalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Raleway";
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/raleway/raleway-black-webfont.woff2") format("woff2"), url("../fonts/raleway/raleway-black-webfont.woff") format("woff"); }

@font-face {
  font-family: "Raleway";
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/raleway/raleway-blackitalic-webfont.woff2") format("woff2"), url("../fonts/raleway/raleway-blackitalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/opensans/opensans-light-webfont.woff2") format("woff2"), url("../fonts/opensans/opensans-light-webfont.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/opensans/opensans-lightitalic-webfont.woff2") format("woff2"), url("../fonts/opensans/opensans-lightitalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/opensans/opensans-regular-webfont.woff2") format("woff2"), url("../fonts/opensans/opensans-regular-webfont.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/opensans/opensans-italic-webfont.woff2") format("woff2"), url("../fonts/opensans/opensans-italic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/opensans/opensans-semibold-webfont.woff2") format("woff2"), url("../fonts/opensans/opensans-semibold-webfont.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/opensans/opensans-semibolditalic-webfont.woff2") format("woff2"), url("../fonts/opensans/opensans-semibolditalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/opensans/opensans-bold-webfont.woff2") format("woff2"), url("../fonts/opensans/opensans-bold-webfont.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/opensans/opensans-bolditalic-webfont.woff2") format("woff2"), url("../fonts/opensans/opensans-bolditalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/opensans/opensans-extrabold-webfont.woff2") format("woff2"), url("../fonts/opensans/opensans-extrabold-webfont.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-weight: 800;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/opensans/opensans-extrabolditalic-webfont.woff2") format("woff2"), url("../fonts/opensans/opensans-extrabolditalic-webfont.woff") format("woff"); }

html {
  box-sizing: border-box;
  font-size: 100%;
  line-height: 1.4; }

*,
*::before,
*::after {
  box-sizing: inherit; }

:root {
  -moz-tab-size: 4;
  tab-size: 4; }

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.4;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

hr {
  height: 0; }

abbr[title] {
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp,
pre {
  font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.4;
  margin: 0; }

button,
select {
  text-transform: none; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  padding: 0; }

progress {
  vertical-align: baseline; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

summary {
  display: list-item; }

body {
  left: 0;
  position: relative;
  max-width: 100vw;
  transition: left .3s ease-in-out;
  overflow-x: hidden; }

* {
  outline: none; }

button {
  padding: 0;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none; }

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  text-decoration: none; }

h4, h5, h6 {
  font-size: inherit; }

.main {
  position: relative;
  overflow: hidden;
  padding-bottom: 60px; }

.intro {
  max-width: 700px;
  margin-top: 0;
  margin-bottom: 60px; }
  .intro p {
    margin: 0; }
  .intro p + p {
    margin-top: 1em; }

@media screen and (min-width: 768px) {
  .intro--columns {
    max-width: 100%;
    display: flex;
    justify-content: space-between; }
    .intro--columns p {
      width: 46%; }
    .intro--columns p + p {
      margin-top: 0; } }

a {
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: inherit; }

nav ol,
nav ul {
  list-style: none;
  padding: 0;
  margin: 0; }

a.link-arrow {
  position: relative; }
  a.link-arrow .icn {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -20px;
    top: 50%;
    margin-top: -8px; }
    a.link-arrow .icn svg {
      display: block;
      width: 100%;
      height: auto;
      fill: currentColor; }

.search-results a::after,
.site-footer__menu a::after,
.site-footer__address a::after,
.article.single a::after {
  content: '';
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 0;
  height: 1px;
  opacity: 0;
  background-color: currentColor;
  transition-property: opacity, left, right;
  transition-duration: .3s;
  transition-timing-function: ease-in-out; }

.search-results a:hover::after,
.site-footer__menu a:hover::after,
.site-footer__address a:hover::after,
.article.single a:hover::after {
  opacity: 1;
  left: 0;
  right: 0; }

.search-results .is-active a::after,
.site-footer__menu .is-active a::after,
.site-footer__address .is-active a::after,
.article.single .is-active a::after {
  opacity: 1;
  left: 0;
  right: 0; }

.site-footer__address .site-footer__tel::after,
.site-footer__address .site-footer__email::after {
  opacity: 1;
  left: 0;
  right: 0; }

.fact__visual,
.workshop-small__visual,
.room__visual,
.block__visual,
.hero-banner__visual,
.hero__visual {
  background-color: #2E3554; }
  .fact__visual a,
  .workshop-small__visual a,
  .room__visual a,
  .block__visual a,
  .hero-banner__visual a,
  .hero__visual a {
    display: block; }
  .fact__visual img,
  .workshop-small__visual img,
  .room__visual img,
  .block__visual img,
  .hero-banner__visual img,
  .hero__visual img {
    filter: grayscale(100%);
    z-index: 0;
    mix-blend-mode: screen; }

.block > a {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4; }
  .block > a:hover + .block__visual img {
    transform: scale(1.08); }
  .block > a:hover + .block__visual + .block__content .btn, .block > a:hover + .block__visual + .info-block .btn {
    color: #fff;
    background-color: #242A43; }
    .block > a:hover + .block__visual + .block__content .btn .icn,
    .block > a:hover + .block__visual + .block__content .btn .btn__text + .icn, .block > a:hover + .block__visual + .info-block .btn .icn,
    .block > a:hover + .block__visual + .info-block .btn .btn__text + .icn {
      margin: 0 !important; }

.constricted {
  max-width: 1440px;
  margin: auto;
  position: relative;
  padding: 0 20px; }
  @media screen and (min-width: 768px) {
    .constricted {
      padding: 0 40px; } }

@media screen and (min-width: 600px) {
  .grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; } }

.grid .col {
  position: relative;
  margin: 0;
  width: 100%; }

.grid--agenda {
  margin: -6px; }
  .grid--agenda .col {
    padding: 0 6px;
    margin: 0 0 100px 0; }
    @media screen and (min-width: 600px) {
      .grid--agenda .col {
        width: 50%; } }
    @media screen and (min-width: 990px) {
      .grid--agenda .col {
        width: 33.333%; } }
    @media screen and (min-width: 1100px) {
      .grid--agenda .col {
        width: 25%; } }

.grid--nav-education {
  margin: auto;
  justify-content: space-between; }
  .grid--nav-education .col {
    width: calc(33.333% - 40px); }

.grid--education {
  max-width: 1125px;
  margin: auto;
  justify-content: space-between; }
  @media screen and (min-width: 600px) {
    .grid--education .col {
      width: calc(50% - 30px);
      margin-bottom: 30px; } }
  @media screen and (min-width: 990px) {
    .grid--education .col {
      width: calc(33.333% - 50px); } }
  @media screen and (min-width: 1100px) {
    .grid--education .col {
      width: calc(33.333% - 80px); } }

.grid.grid--for-rent {
  margin-bottom: -60px;
  display: block; }
  @media screen and (min-width: 600px) {
    .grid.grid--for-rent {
      display: flex; }
      .grid.grid--for-rent .col {
        width: 100%;
        margin: 1px 0; } }
  @media screen and (min-width: 990px) {
    .grid.grid--for-rent .col {
      width: calc(50% - 2px);
      margin: 1px; } }

.grid--lesson-packages {
  max-width: 1220px;
  margin: auto;
  justify-content: space-between; }
  .grid--lesson-packages .col {
    margin-bottom: 76px; }
    .grid--lesson-packages .col .block__visual {
      position: relative;
      z-index: 2;
      mix-blend-mode: multiply; }
    .grid--lesson-packages .col:nth-child(6n+1)::after {
      background-image: linear-gradient(0deg, #FB3E4B 2px, transparent 2px);
      background-size: 8px 8px;
      background-position: 0 -6px;
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -45px;
      width: 100vw;
      height: 90px;
      transform: translateX(100%);
      z-index: -1; }
    @media screen and (min-width: 600px) {
      .grid--lesson-packages .col {
        width: calc(50% - 38px); } }
    @media screen and (min-width: 990px) {
      .grid--lesson-packages .col {
        width: calc(33.333% - 76px); } }

.grid.grid--lessons .col {
  margin-bottom: 35px; }

@media screen and (min-width: 990px) {
  .grid.grid--lessons {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-auto-flow: dense;
    grid-row-gap: 35px; }
    .grid.grid--lessons .col {
      margin: 0; }
      .grid.grid--lessons .col:nth-child(odd) {
        grid-column: 1 / span 1; }
      .grid.grid--lessons .col:nth-child(even) {
        grid-column: 2 / span 1; } }

@media screen and (min-width: 1100px) {
  .grid.grid--lessons {
    display: grid;
    grid-template-columns: 33.333% 33.333% 33.333%;
    grid-auto-flow: dense;
    grid-row-gap: 70px; }
    .grid.grid--lessons .col:nth-child(6n + 1) {
      grid-column: 1 / span 1; }
    .grid.grid--lessons .col:nth-child(6n + 2), .grid.grid--lessons .col:nth-child(6n + 3) {
      grid-column: 2 / span 2;
      padding-left: 70px; }
    .grid.grid--lessons .col:nth-child(6n + 4), .grid.grid--lessons .col:nth-child(6n + 5) {
      grid-column: 1 / span 2;
      padding-right: 70px; }
    .grid.grid--lessons .col:nth-child(6n + 6) {
      grid-column: 3 / span 1; }
    .grid.grid--lessons .col:nth-child(6n + 1), .grid.grid--lessons .col:nth-child(6n + 6) {
      grid-row: auto / span 2; } }

@media screen and (min-width: 1100px) {
  .grid.grid--news {
    display: grid;
    grid-template-columns: 33.333% 33.333% 33.333%;
    grid-row-gap: 20px; } }

@media screen and (min-width: 1100px) {
  .grid.grid--news .col:nth-child(6n + 1) {
    grid-column: 1 / span 1;
    grid-row: auto / span 2; } }

@media screen and (min-width: 1100px) {
  .grid.grid--news .col:nth-child(6n + 2) {
    grid-column: 2 / span 2;
    grid-row: auto / span 1; } }

@media screen and (min-width: 1100px) {
  .grid.grid--news .col:nth-child(6n + 3) {
    grid-column: 2 / span 2;
    grid-row: auto / span 1; } }

@media screen and (min-width: 1100px) {
  .grid.grid--news .col:nth-child(6n + 4) {
    grid-column: 1 / span 2;
    grid-row: auto / span 1; } }

@media screen and (min-width: 1100px) {
  .grid.grid--news .col:nth-child(6n + 5) {
    grid-column: 1 / span 2;
    grid-row: auto / span 1; } }

@media screen and (min-width: 1100px) {
  .grid.grid--news .col:nth-child(6n + 6) {
    grid-column: 3 / span 1;
    grid-row: auto / span 2; } }

.grid.grid--partners {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }
  .grid.grid--partners .col {
    margin: 5px;
    width: auto; }
    @media screen and (min-width: 768px) {
      .grid.grid--partners .col {
        margin: 10px; } }
    @media screen and (min-width: 1100px) {
      .grid.grid--partners .col {
        margin: 20px; } }

.grid--related {
  max-width: 1030px;
  margin: 40px 0 40px 0;
  justify-content: space-between; }
  @media screen and (min-width: 600px) {
    .grid--related .col {
      width: calc(50% - 20px); } }
  @media screen and (min-width: 990px) {
    .grid--related .col {
      width: calc(33.333% - 20px); } }

.grid--team {
  max-width: 1125px;
  margin: auto;
  justify-content: space-between; }
  .grid--team .col {
    margin-bottom: 50px; }
    @media screen and (min-width: 600px) {
      .grid--team .col {
        width: calc(50% - 30px);
        margin-bottom: 30px; } }
    @media screen and (min-width: 990px) {
      .grid--team .col {
        width: calc(33.333% - 50px); } }
    @media screen and (min-width: 1100px) {
      .grid--team .col {
        width: calc(33.333% - 80px); } }

.grid--workshops {
  max-width: 1125px;
  margin: auto;
  justify-content: space-between; }
  .grid--workshops .col {
    margin-bottom: 30px; }
    @media screen and (min-width: 600px) {
      .grid--workshops .col {
        width: calc(50% - 30px);
        margin-bottom: 30px; } }
    @media screen and (min-width: 990px) {
      .grid--workshops .col {
        width: calc(33.333% - 66px);
        margin-bottom: 66px; } }
    @media screen and (min-width: 768px) {
      .grid--workshops .col:nth-child(6n+2) {
        z-index: 3; }
        .grid--workshops .col:nth-child(6n+2) figure {
          position: relative; }
          .grid--workshops .col:nth-child(6n+2) figure::after {
            background-image: linear-gradient(0deg, #FB3E4B 2px, transparent 2px);
            background-size: 8px 8px;
            background-position: 0 -6px;
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -45px;
            width: 100vw;
            height: 90px;
            transform: translateX(100%); }
      .grid--workshops .col:nth-child(6n+3) {
        z-index: 2; }
        .grid--workshops .col:nth-child(6n+3) figure::before {
          z-index: 2;
          content: '';
          display: block;
          width: 100vw;
          height: 150px;
          background: #FB3E4B;
          mix-blend-mode: multiply;
          position: absolute;
          margin-left: 76px;
          transform: translateX(-100%) skew(-45deg); } }
    @media screen and (min-width: 990px) {
      .grid--workshops .col:nth-child(6n+3) figure::before,
      .grid--workshops .col:nth-child(6n+3) figure::after, .grid--workshops .col:nth-child(6n+2) figure::before,
      .grid--workshops .col:nth-child(6n+2) figure::after {
        display: none; }
      .grid--workshops .col:nth-child(6n+3) {
        z-index: 3; }
        .grid--workshops .col:nth-child(6n+3) figure {
          position: relative; }
          .grid--workshops .col:nth-child(6n+3) figure::after {
            background-image: linear-gradient(0deg, #FB3E4B 2px, transparent 2px);
            background-size: 8px 8px;
            background-position: 0 -6px;
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -45px;
            width: 100vw;
            height: 90px;
            transform: translateX(100%); } }
    @media screen and (min-width: 990px) {
      .grid--workshops .col:nth-child(6n+4) {
        z-index: 2; }
        .grid--workshops .col:nth-child(6n+4) figure::before {
          z-index: 2;
          content: '';
          display: block;
          width: 100vw;
          height: 150px;
          background: #FB3E4B;
          mix-blend-mode: multiply;
          position: absolute;
          margin-left: 76px;
          transform: translateX(-100%) skew(-45deg); } }

.title {
  font-family: "Raleway", sans-serif;
  position: relative;
  text-transform: uppercase;
  color: #2E3554; }

.title--main {
  font-size: 1.625rem;
  font-weight: 900;
  letter-spacing: 0.18462em;
  position: relative;
  padding-top: .5em;
  position: relative;
  line-height: 1;
  margin: 50px 0; }
  .title--main, .title--main::after {
    mix-blend-mode: multiply; }
  .title--main::before {
    background-image: linear-gradient(0deg, #FB3E4B 2px, transparent 2px);
    background-size: 8px 8px;
    background-position: 0 -6px;
    content: '';
    display: block;
    position: absolute;
    width: 100vw;
    top: 2px;
    height: 42px;
    left: -10px;
    transform: translateX(-100%); }
  .title--main::after {
    content: attr(data-title);
    color: #FB3E4B;
    position: absolute;
    top: 0;
    left: 0; }
  @media screen and (min-width: 768px) {
    .title--main {
      font-size: 2rem;
      letter-spacing: 0.15em; } }

.title--sub {
  font-size: 1.375rem;
  font-weight: 900;
  letter-spacing: 0.13636em; }
  .title--sub span {
    padding-bottom: 0.24138em;
    display: inline-block;
    position: relative; }
    .title--sub span::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 7px;
      background-image: repeating-linear-gradient(45deg, transparent, #2E3554, transparent 1px, transparent 3px); }
  @media screen and (min-width: 768px) {
    .title--sub {
      font-size: 1.8125rem;
      letter-spacing: 0.10345em; } }

.article__inner {
  margin-top: 2.5rem; }
  @media screen and (min-width: 1100px) {
    .article__inner {
      margin-top: 3.75rem; } }

.article__content, .article__media {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto; }

.article__sidebar {
  margin-bottom: 40px; }

.article__content figure {
  margin: 0;
  width: 100%;
  display: block; }

.article__content img {
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 20px);
  margin: 0;
  height: auto;
  margin-top: 40px;
  margin-bottom: 40px; }
  .article__content img:nth-child(2n + 2) {
    margin-left: 20px; }
  .article__content img:first-of-type:last-of-type {
    display: block;
    width: 100%;
    height: auto; }

.article__media__item {
  position: relative;
  width: 100%; }
  .article__media__item .article__media__item + .article__media__item {
    margin-top: 40px; }
  .article__media__item > iframe {
    display: block;
    margin: 0; }
  .article__media__item.vimeo:before, .article__media__item.youtube:before, .article__media__item.soundcloud:before {
    display: block;
    width: 100%;
    content: ' '; }
  .article__media__item.vimeo > iframe, .article__media__item.youtube > iframe, .article__media__item.soundcloud > iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }
  .article__media__item.soundcloud:before {
    padding-top: 20.13423%; }
  .article__media__item.vimeo:before, .article__media__item.youtube:before {
    padding-top: 56.25%; }

.article.has-sidebar .article__inner {
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 768px) {
    .article.has-sidebar .article__inner {
      display: grid;
      grid-template-columns: 56.666% 10% 33.333%;
      grid-template-rows: auto; } }
  @media screen and (min-width: 1100px) {
    .article.has-sidebar .article__inner {
      grid-template-columns: 56.666% 10% 33.333%; } }

.article.has-sidebar .article__content,
.article.has-sidebar .article__media {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  order: 2; }
  @media screen and (min-width: 768px) {
    .article.has-sidebar .article__content,
    .article.has-sidebar .article__media {
      grid-column: 1 / span 1; } }

.article.has-sidebar .article__sidebar {
  order: 1; }
  @media screen and (min-width: 768px) {
    .article.has-sidebar .article__sidebar {
      grid-column: 3 / span 3;
      grid-row: 1 / 1; } }

.article.has-sidebar .article__related {
  order: 99; }
  @media screen and (min-width: 768px) {
    .article.has-sidebar .article__related {
      grid-column: 1 / span 3; } }

.article__content p:first-child {
  margin-top: 0; }

.article__related {
  margin-top: 60px; }

.article__content + .article__related {
  margin-top: 120px; }

.article__header {
  color: #2E3554; }
  @media screen and (min-width: 1100px) {
    .article__header {
      color: white;
      top: -274px;
      position: relative; }
      .article__header__inner {
        left: 60%;
        right: 0;
        position: absolute; } }

.article__header__title {
  font-size: 0.875rem;
  font-weight: 900;
  line-height: 1.71429;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  margin: 20px 0 0 0; }
  @media screen and (min-width: 768px) {
    .article__header__title {
      font-size: 1.125rem;
      line-height: 1.33333;
      letter-spacing: 0.09722em;
      margin: 20px 0 0 0; } }
  @media screen and (min-width: 1100px) {
    .article__header__title {
      font-size: 1.3125rem;
      line-height: 1.14286;
      letter-spacing: 0.08333em;
      margin: 50px 0 0; } }

.article__header .date-list {
  margin: 1em 0; }
  .article__header .date-list__item {
    display: inline-block; }
  .article__header .date-list li:last-child span {
    display: none; }

.article__header .tags {
  display: none; }
  @media screen and (min-width: 1100px) {
    .article__header .tags {
      display: block; } }

.workshop-small {
  display: flex;
  align-items: stretch;
  height: 100%; }
  .workshop-small__visual-wrapper {
    width: 50%;
    display: none; }
    @media screen and (min-width: 1100px) {
      .workshop-small__visual-wrapper {
        display: block; } }
  .workshop-small__visual {
    margin: 0;
    width: 100%;
    font-size: 0.75rem;
    position: relative; }
    .workshop-small__visual:before {
      display: block;
      padding-top: 66.66667%;
      width: 100%;
      content: ' '; }
    .workshop-small__visual a {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; }
    .workshop-small__visual img {
      display: block;
      margin: 0;
      width: 100%;
      height: auto;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
  .workshop-small__content {
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 1100px) {
      .workshop-small__content {
        padding-left: 20px;
        width: 50%; } }
    .workshop-small__content a {
      align-self: flex-end; }
  .workshop-small__title {
    font-family: "Open Sans", sans-serif;
    font-size: 1.125rem;
    font-weight: 900;
    letter-spacing: 0.12222em;
    margin: 0;
    color: #FB3E4B;
    text-transform: uppercase; }
  .workshop-small__description {
    font-family: "Raleway", sans-serif;
    font-size: 0.9375rem;
    font-weight: 300;
    line-height: 1.5;
    margin: 0;
    flex-grow: 1;
    color: #242A43; }

.block--workshop .block__visual {
  overflow: hidden; }
  .block--workshop .block__visual img {
    transition: transform .3s ease-in-out; }

.background-bar {
  height: 60px;
  background-image: url("../img/bar.svg");
  background-size: 800px auto;
  background-position: 70% center;
  background-repeat: no-repeat; }
  @media screen and (min-width: 600px) {
    .background-bar {
      background-size: 120% auto;
      height: 90px; } }
  @media screen and (min-width: 1100px) {
    .background-bar {
      background: none;
      position: absolute;
      width: 100%;
      height: 130px;
      overflow: hidden;
      top: 50%;
      margin-top: -130px;
      z-index: -1; }
      .background-bar::before {
        background-image: linear-gradient(90deg, #fff 1px, transparent 1px);
        background-size: 10px 10px;
        background-position: 0 0;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: calc(50% + 600px);
        height: 65px;
        z-index: 9; }
      .background-bar__top, .background-bar__bottom {
        display: block;
        position: absolute;
        height: 65px;
        background-color: #FB3E4B; }
      .background-bar__top {
        width: calc(50% + 550px);
        transform: skew(45deg);
        background-color: #FB3E4B;
        left: -35px;
        top: 0;
        overflow: hidden; }
        .background-bar__top::before, .background-bar__top::after {
          content: '';
          display: block;
          height: 65px;
          position: absolute; }
        .background-bar__top::before {
          width: 110px;
          background-color: #2E3554;
          right: 0;
          z-index: 1; }
        .background-bar__top::after {
          transform: skew(-45deg);
          background-color: #2E3554;
          width: 380px;
          right: 35px;
          mix-blend-mode: multiply;
          z-index: 0; }
      .background-bar__bottom {
        z-index: 2;
        top: 65px;
        left: calc(50% + 358px);
        right: -35px;
        transform: skew(45deg); }
        .background-bar__bottom::before {
          content: '';
          display: block;
          position: absolute;
          left: 110px;
          width: 100%;
          height: 100%;
          background-color: #2E3554;
          mix-blend-mode: multiply;
          right: 0;
          z-index: 1; } }

@media screen and (min-width: 1100px) {
  .background-bar--wider::before {
    right: calc(50% + 730px); }
  .background-bar--wider .background-bar__top {
    width: calc(50% + 880px); }
  .background-bar--wider .background-bar__bottom {
    left: calc(50% + 690px); } }

.block {
  position: relative; }
  .block__visual {
    margin: 0 15px;
    min-height: 80px; }
    .block__visual img {
      display: block;
      width: 100%;
      height: auto; }
  .block__content {
    position: relative;
    margin-top: -15px; }
    @media screen and (min-width: 1100px) {
      .block__content {
        margin-top: -30px; } }
  .block__title {
    font-family: "Raleway", sans-serif;
    font-size: 1.3125rem;
    font-weight: 900;
    line-height: 1.14286;
    letter-spacing: 0.08333em;
    text-transform: uppercase;
    margin: 0;
    color: #fff; }
  .block__postdate, .block__date {
    margin: 0;
    color: #fff; }

.block-meta {
  height: 50px;
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  overflow: hidden; }
  .block-meta .block-meta__logo-bar {
    position: absolute;
    height: 34px;
    padding: 5px 15px; }
    .block-meta .block-meta__logo-bar::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: -20px;
      background-color: #fff;
      transform: skew(-45deg);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); }
  .block-meta .media-icon {
    width: 35px;
    height: 35px;
    position: absolute;
    right: 0;
    top: 10px;
    color: #2E3554;
    background-color: white; }
    .block-meta .media-icon .icn svg {
      fill: #2E3554; }
  .block-meta .meta-organisator {
    opacity: 1;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    background: white;
    line-height: 0;
    padding: 7px 10px; }
    .block-meta .meta-organisator:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 32px 32px 0 0;
      border-color: #ffffff transparent transparent transparent;
      position: absolute;
      left: 100%;
      top: 0; }
  .block-meta .event-logos {
    position: relative;
    z-index: 2; }
    .block-meta .event-logos img {
      display: inline-block;
      margin: 0;
      width: auto;
      height: 24px; }

.block.block--education {
  position: relative;
  margin: 20px 0; }
  .block.block--education:after {
    display: table;
    clear: both;
    content: ''; }
  @media screen and (min-width: 600px) {
    .block.block--education {
      display: block;
      margin: 0; } }
  .block.block--education > a {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2; }
    .block.block--education > a:hover + .block__visual img {
      transform: scale(1.08); }
    .block.block--education > a:hover + .block__visual + .block__content .btn {
      color: #fff;
      background-color: #242A43; }
      .block.block--education > a:hover + .block__visual + .block__content .btn .icn,
      .block.block--education > a:hover + .block__visual + .block__content .btn .btn__text + .icn {
        margin: 0 !important; }
  .block.block--education .block__visual {
    width: 125px;
    margin: 0;
    position: absolute;
    top: 50%;
    overflow: hidden;
    transform: translateY(-50%); }
    @media screen and (min-width: 600px) {
      .block.block--education .block__visual {
        position: relative;
        transform: none;
        top: auto;
        margin: 0 15px;
        width: auto;
        height: auto; } }
    .block.block--education .block__visual img {
      transition: all .3s ease-in-out; }
  .block.block--education .block__content {
    margin-top: 0;
    width: calc(100% - 115px);
    float: right; }
    @media screen and (min-width: 600px) {
      .block.block--education .block__content {
        position: relative;
        margin-top: -30px;
        width: auto;
        float: none; } }
    .block.block--education .block__content .info-block {
      padding: 20px 45px 20px 25px; }
    .block.block--education .block__content .info-block__button {
      margin: -20px -45px -20px 0; }

@media screen and (max-width: 600px) {
  .col:nth-child(even) .block.block--education .block__visual {
    margin-left: 10px; }
  .col:nth-child(even) .block.block--education .block__content .info-block {
    padding-left: 35px; }
  .col:nth-child(even) .block.block--education .block__content .info-block::before {
    display: block;
    width: 20px;
    left: -115px; } }

.block--lesson-package .block__visual {
  margin: auto;
  height: 40px;
  margin-bottom: -10px;
  text-align: center;
  background-color: transparent; }
  .block--lesson-package .block__visual img {
    display: inline-block;
    box-shadow: none;
    height: 100%;
    width: auto;
    transition: transform .3s ease-in-out; }

.block--lesson-package .info-block {
  padding: 35px; }
  .block--lesson-package .info-block__title {
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: 0.12222em;
    margin-bottom: 10px; }
  .block--lesson-package .info-block__button {
    margin: -35px;
    margin-top: 0; }

@media screen and (min-width: 1100px) {
  .block--lesson {
    display: flex;
    flex-wrap: nowrap; } }

.block--lesson::before {
  content: '';
  display: block;
  background-color: #FB3E4B;
  width: 19px;
  position: absolute;
  top: -10px;
  left: -10px;
  bottom: 0;
  z-index: 2;
  mix-blend-mode: multiply; }
  @media screen and (min-width: 1100px) {
    .block--lesson::before {
      display: none; } }

.block--lesson > .block__header {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none; }
  .block--lesson > .block__header figure {
    order: 1; }
  .block--lesson > .block__header .info-block__title {
    padding: 0 0 0 20px;
    width: calc(100% - 200px);
    word-break: break-all;
    order: 2; }
    @media screen and (min-width: 600px) {
      .block--lesson > .block__header .info-block__title {
        width: auto;
        word-break: normal; } }
  .block--lesson > .block__header > .icn {
    right: -10px;
    position: relative;
    transform: scaleY(-1);
    order: 99; }
    .block--lesson > .block__header > .icn > svg {
      fill: #FB3E4B; }
    @media screen and (min-width: 600px) {
      .block--lesson > .block__header > .icn {
        right: auto; } }
  @media screen and (min-width: 1100px) {
    .block--lesson > .block__header {
      display: block;
      cursor: default; } }

.block--lesson.is-closed .block__header .icn {
  transform: scaleY(1); }

.block--lesson figure {
  width: 160px;
  margin: 0; }
  @media screen and (min-width: 1100px) {
    .block--lesson figure {
      margin: 10px 0 0 0;
      width: 310px; } }

.block--lesson .info-block {
  padding: 10px 10px 0 20px;
  color: #2E3554;
  margin: 0;
  overflow: hidden;
  transition: height .3s ease-in-out; }
  @media screen and (min-width: 1100px) {
    .block--lesson .info-block {
      width: calc(100% - 310px);
      margin: 0 0 0 -12px;
      padding: 10px 0 0 45px;
      overflow: visible; } }
  .block--lesson .info-block__title {
    font-size: 1.125rem;
    font-weight: 700;
    color: #FB3E4B; }
    .block--lesson .info-block__title.is-hidden {
      display: none; }
  .block--lesson .info-block .icon-list {
    margin: 1em 0; }
  .block--lesson .info-block .icon-list .icn svg {
    fill: currentColor; }
  .block--lesson .info-block::before {
    display: none; }
    @media screen and (min-width: 1100px) {
      .block--lesson .info-block::before {
        display: block;
        width: 24px; } }

@media screen and (min-width: 1100px) {
  .grid.grid--lessons .col:nth-child(6n + 1) .block--lesson, .grid.grid--lessons .col:nth-child(6n + 6) .block--lesson {
    flex-direction: column; }
    .grid.grid--lessons .col:nth-child(6n + 1) .block--lesson .info-block, .grid.grid--lessons .col:nth-child(6n + 6) .block--lesson .info-block {
      width: 100%;
      margin: 0 0 0 0; }
      .grid.grid--lessons .col:nth-child(6n + 1) .block--lesson .info-block::before, .grid.grid--lessons .col:nth-child(6n + 6) .block--lesson .info-block::before {
        top: -25px;
        bottom: 0;
        height: auto; } }

.block--news {
  position: relative;
  margin-bottom: 30px; }
  @media screen and (min-width: 768px) {
    .block--news {
      margin: auto; } }
  .block--news > a {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2; }
    .block--news > a:hover + .block__visual img {
      transform: translate(-50%, -50%) scale(1.08); }
    .block--news > a:hover + .block__visual + .block__content .btn {
      color: #fff;
      background-color: #242A43; }
      .block--news > a:hover + .block__visual + .block__content .btn .icn,
      .block--news > a:hover + .block__visual + .block__content .btn .btn__text + .icn {
        margin: 0 !important; }
  .block--news .block__visual {
    overflow: hidden;
    position: relative; }
    .block--news .block__visual:before {
      display: block;
      width: 100%;
      content: ' ';
      padding-top: 29.72973%; }
      @media screen and (min-width: 768px) {
        .block--news .block__visual:before {
          padding-top: 63.23529%; } }
      @media screen and (min-width: 1100px) {
        .block--news .block__visual:before {
          padding-top: 129.41176%; } }
    .block--news .block__visual img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      transition: all .3s ease-in-out; }
      @media screen and (min-width: 1100px) {
        .block--news .block__visual img {
          width: auto;
          height: 100%; } }
    .block--news .block__visual a {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .block--news .info-block__title {
    font-size: 1.125rem;
    line-height: 1.33333;
    letter-spacing: 0.09722em; }

@media screen and (min-width: 768px) {
  .grid.grid--news .col:nth-child(6n + 1) .block, .grid.grid--news .col:nth-child(6n + 6) .block {
    max-width: 370px;
    margin-bottom: 30px; }
  .grid.grid--news .col:nth-child(6n + 2), .grid.grid--news .col:nth-child(6n + 3), .grid.grid--news .col:nth-child(6n + 4), .grid.grid--news .col:nth-child(6n + 5) {
    margin-bottom: 30px; }
  .grid.grid--news .col:nth-child(6n + 6) {
    display: flex;
    justify-content: flex-end; }
    .grid.grid--news .col:nth-child(6n + 6) .block {
      width: 100%; }
  .grid.grid--news .col:nth-child(6n + 2) .block {
    z-index: 2; }
  .grid.grid--news .col:nth-child(6n + 2) .block, .grid.grid--news .col:nth-child(6n + 3) .block, .grid.grid--news .col:nth-child(6n + 4) .block, .grid.grid--news .col:nth-child(6n + 5) .block {
    display: flex; }
  .grid.grid--news .col:nth-child(6n + 2) .block__content, .grid.grid--news .col:nth-child(6n + 3) .block__content, .grid.grid--news .col:nth-child(6n + 4) .block__content, .grid.grid--news .col:nth-child(6n + 5) .block__content {
    margin: 0;
    flex-grow: 1;
    min-width: 280px; }
  .grid.grid--news .col:nth-child(6n + 2) .block__visual, .grid.grid--news .col:nth-child(6n + 3) .block__visual, .grid.grid--news .col:nth-child(6n + 4) .block__visual, .grid.grid--news .col:nth-child(6n + 5) .block__visual {
    width: 540px;
    margin: 0; }
    .grid.grid--news .col:nth-child(6n + 2) .block__visual:before, .grid.grid--news .col:nth-child(6n + 3) .block__visual:before, .grid.grid--news .col:nth-child(6n + 4) .block__visual:before, .grid.grid--news .col:nth-child(6n + 5) .block__visual:before {
      display: block;
      padding-top: 47.22222%;
      width: 100%;
      content: ' '; }
    .grid.grid--news .col:nth-child(6n + 2) .block__visual img, .grid.grid--news .col:nth-child(6n + 3) .block__visual img, .grid.grid--news .col:nth-child(6n + 4) .block__visual img, .grid.grid--news .col:nth-child(6n + 5) .block__visual img {
      width: 100%;
      height: auto; }
  .grid.grid--news .col:nth-child(6n + 2) .info-block, .grid.grid--news .col:nth-child(6n + 3) .info-block, .grid.grid--news .col:nth-child(6n + 4) .info-block, .grid.grid--news .col:nth-child(6n + 5) .info-block {
    padding-top: 0; }
  .grid.grid--news .col:nth-child(6n + 2) .info-block, .grid.grid--news .col:nth-child(6n + 4) .info-block, .grid.grid--news .col:nth-child(6n + 5) .info-block {
    color: #2E3554;
    border-bottom: 1px solid #FB3E4B; }
    .grid.grid--news .col:nth-child(6n + 2) .info-block::before, .grid.grid--news .col:nth-child(6n + 4) .info-block::before, .grid.grid--news .col:nth-child(6n + 5) .info-block::before {
      display: none; }
  .grid.grid--news .col:nth-child(6n + 2) .info-block__title, .grid.grid--news .col:nth-child(6n + 2) .info-block__title a, .grid.grid--news .col:nth-child(6n + 4) .info-block__title, .grid.grid--news .col:nth-child(6n + 4) .info-block__title a, .grid.grid--news .col:nth-child(6n + 5) .info-block__title, .grid.grid--news .col:nth-child(6n + 5) .info-block__title a {
    color: #FB3E4B; }
  .grid.grid--news .col:nth-child(6n + 2) .btn, .grid.grid--news .col:nth-child(6n + 4) .btn, .grid.grid--news .col:nth-child(6n + 5) .btn {
    background-color: #FB3E4B;
    color: white; }
    .grid.grid--news .col:nth-child(6n + 2) .btn:hover, .grid.grid--news .col:nth-child(6n + 4) .btn:hover, .grid.grid--news .col:nth-child(6n + 5) .btn:hover {
      background-color: #2E3554; }
  .grid.grid--news .col:nth-child(6n + 3) .info-block::before {
    top: -40px;
    bottom: 0;
    left: -80px;
    width: 100vw;
    height: auto;
    transform: skew(45deg); } }

@media screen and (min-width: 1100px) {
  .grid.grid--news .col:nth-child(6n + 2), .grid.grid--news .col:nth-child(6n + 3) {
    padding-left: 30px;
    margin: 0; }
  .grid.grid--news .col:nth-child(6n + 4), .grid.grid--news .col:nth-child(6n + 5) {
    padding-right: 30px;
    margin: 0; }
  .grid.grid--news .col:nth-child(6n + 2) .block {
    margin-top: 60px;
    z-index: 2; }
  .grid.grid--news .col:nth-child(6n + 6) .block, .grid.grid--news .col:nth-child(6n + 3) .block {
    margin-bottom: 60px; } }

.block--partner figure {
  margin: 0; }
  .block--partner figure img {
    max-height: 200px;
    width: auto; }

.room {
  width: 100%;
  position: relative; }
  .room > a {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2; }
    .room > a:hover + .room__visual img {
      transform: scale(1.08); }
    .room > a:hover + .room__visual + .room__content .btn {
      color: #fff;
      background-color: #242A43; }
      .room > a:hover + .room__visual + .room__content .btn .icn,
      .room > a:hover + .room__visual + .room__content .btn .btn__text + .icn {
        margin: 0 !important; }
  .room__visual {
    width: 100%;
    margin: 0;
    z-index: 0;
    position: relative;
    overflow: hidden; }
    .room__visual:before {
      display: block;
      width: 100%;
      content: ' ';
      padding-top: 65.33333%; }
    .room__visual img {
      width: 100%;
      height: auto;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) scale(1);
      transition: all .5s ease-in-out; }
      @media screen and (min-width: 990px) {
        .room__visual img {
          position: relative;
          top: auto;
          transform: none; } }
    @media screen and (min-width: 990px) {
      .room__visual {
        height: auto; }
        .room__visual::before {
          display: none; } }
  .room__content {
    position: relative;
    top: -50px;
    left: 50%;
    width: 300px;
    margin-left: -150px; }
    @media screen and (min-width: 600px) {
      .room__content {
        position: absolute;
        bottom: 30px;
        top: auto; } }
    .room__content::before {
      content: '';
      position: absolute;
      display: block;
      background-color: #FB3E4B;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      mix-blend-mode: multiply; }
  .room__widget {
    color: #fff;
    z-index: 2;
    position: relative; }
    .room__widget__content, .room__widget__title {
      margin: 0;
      padding: 10px 35px 10px 15px; }
    .room__widget__title {
      font-size: 1.25rem;
      text-transform: uppercase; }
      .room__widget__title a {
        color: currentColor; }
    .room__widget__content {
      background-color: #FB3E4B; }
    .room__widget .btn--small {
      position: absolute;
      right: 0;
      bottom: 0; }

@media screen and (max-width: 768px) {
  .block.block--teammember {
    display: flex;
    align-items: center; }
    .block.block--teammember .block__visual {
      margin: 0;
      width: 125px; }
    .block.block--teammember .block__content {
      margin: 0;
      flex-grow: 1;
      width: auto; }
      .block.block--teammember .block__content .info-block::before {
        width: auto;
        left: -10px;
        right: 0; } }

.block--training {
  margin: 60px 0; }
  .block--training.blue-bg::after {
    content: '';
    background-color: #fff;
    display: block;
    position: absolute;
    width: 100vw;
    height: 65px;
    bottom: -50px;
    left: 50%;
    margin-left: 0;
    transform: skew(-45deg); }
    @media screen and (min-width: 768px) {
      .block--training.blue-bg::after {
        bottom: 0; } }
    @media screen and (min-width: 1100px) {
      .block--training.blue-bg::after {
        margin-left: -200px; } }
  .block--training .block__content {
    max-width: 700px;
    margin: 30px 0 40px 0; }
    .block--training .block__content p {
      margin: 0; }
    .block--training .block__content p + p {
      margin-top: 20px; }
    .block--training .block__content .btn {
      margin-top: 30px; }

.grid--related .block a::after {
  display: none; }

.grid--related .block--news .block__visual:before {
  display: block;
  padding-top: 62.5%;
  width: 100%;
  content: ' '; }

.grid--related .block--news .block__visual img {
  width: 100%;
  height: auto; }

.grid--related .block--news figure {
  margin: 0; }

.grid--related .block--news .block__content {
  margin-top: 0; }

.grid--related .block--news .info-block {
  color: #2E3554;
  padding: 10px; }
  .grid--related .block--news .info-block::before {
    display: none; }
  .grid--related .block--news .info-block__title {
    color: #FB3E4B; }
  .grid--related .block--news .info-block__postdate {
    margin-top: 0; }
  .grid--related .block--news .info-block__button {
    margin: -45px -10px 0 0; }

.grid--related .block--news .btn {
  background-color: #FB3E4B;
  color: white; }

.blue-bg {
  color: white;
  background-color: #2E3554;
  position: relative;
  overflow: hidden; }
  .blue-bg .title {
    position: relative;
    display: inline-block;
    background: #fff;
    margin: 0; }
    .blue-bg .title span {
      position: relative;
      z-index: 1; }
    .blue-bg .title::before, .blue-bg .title::after {
      content: '';
      display: block;
      position: absolute;
      background-color: #fff;
      width: 100vw;
      z-index: 0; }
    .blue-bg .title::before {
      height: 65px;
      top: -10px;
      left: calc(100% + 40px);
      transform: translateX(-100%) skew(-45deg); }
      @media screen and (min-width: 768px) {
        .blue-bg .title::before {
          top: 0; } }
    .blue-bg .title::after {
      top: 0;
      left: -20px;
      height: 40px; }
      @media screen and (min-width: 768px) {
        .blue-bg .title::after {
          display: none; } }

.btn {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  transition: color .3s ease-in-out;
  height: 40px;
  padding: 0 0.625rem 0 0.9375rem;
  background-color: #fff;
  color: #FB3E4B;
  transition-property: background-color, color;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
  transition-delay: 0s;
  display: inline-flex;
  align-items: center; }
  .btn + .btn {
    margin-left: 10px; }
  .btn__text {
    display: inline-block;
    margin-right: 0.3125rem; }
  .btn .icn {
    margin-left: 0;
    width: 0;
    overflow: hidden;
    opacity: 0;
    transition-property: opacity, margin, width;
    transition-timing-function: ease-in-out;
    transition-duration: .3s, .3s, .3s;
    transition-delay: 0s, .15s, .15s; }
    .btn .icn svg {
      transition: fill .3s ease-in-out;
      fill: currentColor; }
  .btn:not([disabled]):hover .icn, .btn:not([disabled]):focus .icn {
    width: 35px;
    opacity: 1;
    transition-delay: .15s, 0s, 0s; }
  .btn:not([disabled]):hover .btn__text + .icn, .btn:not([disabled]):focus .btn__text + .icn {
    margin-left: -0.3125rem; }

.btn--primary {
  clip-path: polygon(0 14px, 14px 0, 100% 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 100%);
  padding: 0 0.625rem 0 1.25rem;
  background-color: #FB3E4B;
  color: #fff; }
  .btn--primary .btn__text {
    margin-right: 0.3125rem; }
  .btn--primary:not([disabled]):hover, .btn--primary:not([disabled]):focus {
    background-color: #2E3554; }
    .btn--primary:not([disabled]):hover .icn, .btn--primary:not([disabled]):focus .icn {
      margin-left: -0.9375rem; }
    .btn--primary:not([disabled]):hover .btn__text + .icn, .btn--primary:not([disabled]):focus .btn__text + .icn {
      margin-left: -0.3125rem; }

.btn--secondary {
  color: #fff;
  clip-path: polygon(0 14px, 14px 0, 100% 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 100%);
  background-color: transparent;
  border-right: 2px solid #fff;
  position: relative; }
  .btn--secondary::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 40px;
    background-repeat: no-repeat;
    background-size: auto 100%;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    transition-delay: 0s; }
  .btn--secondary::before {
    background-image: url("../img/ui/btn-white-outline.svg"); }
  .btn--secondary .btn__text {
    position: relative;
    z-index: 2; }
  .btn--secondary .icn {
    position: relative;
    z-index: 2; }
  .btn--secondary:not([disabled]):hover, .btn--secondary:not([disabled]):focus {
    color: #2E3554;
    background-color: #fff; }
    .btn--secondary:not([disabled]):hover .icn, .btn--secondary:not([disabled]):focus .icn {
      margin-left: -0.9375rem; }
    .btn--secondary:not([disabled]):hover .icn svg, .btn--secondary:not([disabled]):focus .icn svg {
      fill: #2E3554; }
    .btn--secondary:not([disabled]):hover::after, .btn--secondary:not([disabled]):focus::after {
      opacity: 1; }

.btn--small {
  display: inline-block;
  width: 35px;
  height: 35px;
  position: relative;
  overflow: hidden;
  position: relative;
  clip-path: none; }
  .btn--small::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%; }
  .btn--small .icn {
    position: absolute;
    opacity: 1;
    width: 35px;
    top: 0;
    left: 0; }
    .btn--small .icn svg {
      fill: currentColor; }
  .btn--small:not([disabled]):hover, .btn--small:not([disabled]):focus {
    color: #fff;
    background-color: #2E3554; }
    .btn--small:not([disabled]):hover .icn,
    .btn--small:not([disabled]):hover .btn__text + .icn, .btn--small:not([disabled]):focus .icn,
    .btn--small:not([disabled]):focus .btn__text + .icn {
      margin: 0 !important; }

.btn--small.btn--secondary {
  border: 2px solid white; }
  .btn--small.btn--secondary::before, .btn--small.btn--secondary::after {
    display: none;
    background-image: none; }
  .btn--small.btn--secondary::before {
    position: relative;
    display: block;
    width: 100%;
    height: 100%; }
  .btn--small.btn--secondary .icn {
    width: 31px;
    height: 31px; }
  .btn--small.btn--secondary:not([disabled]):hover, .btn--small.btn--secondary:not([disabled]):focus {
    background-color: #fff; }
    .btn--small.btn--secondary:not([disabled]):hover .icn, .btn--small.btn--secondary:not([disabled]):focus .icn {
      width: 31px;
      height: 31px; }

.btn--invert {
  color: #2E3554; }
  .btn--invert:not([disabled]):hover, .btn--invert:not([disabled]):focus {
    background-color: #FB3E4B;
    color: #fff; }

.btn--clear {
  background-color: transparent;
  color: inherit; }
  .btn--clear:not([disabled]):hover, .btn--clear:not([disabled]):focus {
    background-color: transparent;
    color: inherit; }

.btn[disabled] {
  opacity: .4;
  cursor: not-allowed; }

.map {
  width: auto;
  height: 355px;
  background: #eee;
  margin: 0 -20px; }
  @media screen and (min-width: 768px) {
    .map {
      order: 1;
      margin: 0;
      width: 525px;
      height: 355px; } }

.contact {
  margin-bottom: -60px; }

.contact__details {
  margin: 1.25rem 0;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .contact__details {
      margin: 3.125rem 0 6.25rem 0;
      display: flex;
      justify-items: stretch; } }
  .contact__details .info-block {
    margin: 0 -20px; }
    @media screen and (min-width: 768px) {
      .contact__details .info-block {
        margin: -10px 0 -10px -30px;
        padding-left: 60px;
        padding-right: 40px;
        height: calc(100% + 20px); } }
    .contact__details .info-block h4 {
      margin: 0; }
    .contact__details .info-block p {
      margin: 10px 0; }

@media screen and (min-width: 768px) {
  .contact__content {
    order: 2; } }

.contact__hours {
  margin-top: 2em; }
  .contact__hours table {
    margin: 10px 0; }
    .contact__hours table th, .contact__hours table td {
      vertical-align: top; }

@media screen and (max-width: 768px) {
  .contact__form .title {
    position: relative; }
    .contact__form .title::before {
      height: auto;
      width: 100vw;
      transform: none;
      left: -20px;
      bottom: -10px; } }

.contact-form-container.hide,
.thankyou-container.hide {
  display: none; }

.thankyou-container {
  padding: 100px 0; }

@media screen and (max-width: 990px) {
  .events-header {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .filter-wrapper {
    position: fixed;
    top: 0;
    left: 100vw;
    bottom: 0;
    width: 100vw;
    z-index: 99;
    overflow: scroll;
    transition: left .3s ease-in-out; }
    .filter-wrapper__inner {
      background-color: #2E3554;
      padding: 30px; }
  [data-mobile-filters="close"] {
    position: absolute;
    top: 10px;
    right: 10px; }
  [data-mobile-filters="close"] {
    display: block; }
  .filter-tag[data-mobile-filters="open"] {
    display: inline-flex; }
  .filter-button-list {
    max-width: 350px;
    margin: auto; }
    .filter-button-list > li {
      margin-bottom: 40px; }
  .filter-buttons {
    text-align: center;
    max-width: 350px;
    margin: auto; }
  .filter-tag {
    display: none; }
  ul {
    list-style: none;
    padding: 0;
    margin: 0; }
  h3 {
    margin: 0 0 1em 0; }
  
  h3,
  .custom-checkbox label,
  .ui-datepicker-header,
  .ui-datepicker-calendar {
    color: white; }
  body.show-filters {
    left: -100vw; }
    body.show-filters .filter-wrapper {
      left: 0; } }

@media screen and (min-width: 990px) {
  .events-header {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  [data-mobile-filters="close"],
  [data-mobile-filters="open"],
  .filter-buttons {
    display: none; }
  .filter-button-list {
    list-style: none;
    padding: 0;
    margin: 0 0 0 50px;
    display: flex; }
    .filter-button-list__item + .filter-button-list__item {
      margin-left: 20px; }
  .filter-list {
    padding: 0;
    margin: 0;
    list-style: none; }
    .filter-list__item + .filter-list__item {
      margin-top: 5px; }
  .filters {
    position: absolute;
    z-index: 9;
    margin-top: 20px;
    user-select: none;
    display: none; }
    .filters h3 {
      display: none; }
    .filters.is-shown {
      display: block; }
    .filters__inner {
      padding: 20px 25px;
      background-color: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); } }

.filter {
  clip-path: polygon(0 14px, 14px 0, 100% 0, 100% 0, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%, 0 100%);
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  transition: color .3s ease-in-out;
  height: 40px;
  padding: 0 0.3125rem 0 1.25rem;
  background-color: #ddd;
  color: #2E3554;
  transition-property: background-color, color;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
  transition-delay: 0s;
  display: inline-flex;
  align-items: center; }
  .filter + .filter-tag,
  .filter + .filter {
    margin-left: 10px; }
  .filter__text {
    display: inline-block;
    margin-right: 0.3125rem; }
  .filter .icn {
    transition-property: transform;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    transition-delay: 0s;
    margin-left: -0.3125rem; }
    .filter .icn svg {
      transition-property: fill, transform;
      transition-timing-function: ease-in-out;
      transition-duration: .3s;
      transition-delay: 0s;
      fill: #2E3554; }
  .filter:hover, .filter:focus {
    background-color: #2E3554;
    color: #fff; }
    .filter:hover .icn, .filter:focus .icn {
      transform: rotate(-180deg); }
    .filter:hover .icn svg, .filter:focus .icn svg {
      fill: #fff; }

.filter-tag {
  clip-path: polygon(0 14px, 14px 0, 100% 0, 100% 0, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%, 0 100%);
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  transition: color .3s ease-in-out;
  height: 40px;
  padding: 0 1.25rem;
  color: #2E3554;
  transition-property: background-color, color;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
  transition-delay: 0s;
  display: inline-flex;
  align-items: center;
  position: relative; }
  .filter-tag + .filter-tag {
    margin-left: 10px; }
  .filter-tag::before, .filter-tag::after {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    width: 20px;
    z-index: 0;
    background-size: auto 40px;
    background-repeat: no-repeat;
    background-image: url("../img/ui/btn-blue-outline.svg"); }
  .filter-tag::before {
    left: 0;
    width: 100%; }
  .filter-tag::after {
    right: 0;
    transform: scale(-1); }
  .filter-tag__text {
    display: inline-block;
    position: relative;
    z-index: 2; }
  .filter-tag .icn {
    transition-property: transform;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    transition-delay: 0s;
    margin-left: -0.3125rem; }
    .filter-tag .icn svg {
      transition-property: fill, transform;
      transition-timing-function: ease-in-out;
      transition-duration: .3s;
      transition-delay: 0s;
      fill: currentColor; }
  .filter-tag.is-selected, .filter-tag:hover, .filter-tag:focus {
    background-color: #2E3554;
    color: #fff; }

.daterangepicker.ltr.show-calendar.opensright {
  border-radius: 0;
  margin-top: 20px;
  border: 0;
  padding: 20px 25px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); }
  .daterangepicker.ltr.show-calendar.opensright:before, .daterangepicker.ltr.show-calendar.opensright:after {
    display: none; }
  .daterangepicker.ltr.show-calendar.opensright td.active, .daterangepicker.ltr.show-calendar.opensright td.active:hover {
    background: #B0CDEA; }

.site-footer {
  padding-top: 23px;
  position: relative;
  color: #fff;
  line-height: 1.5; }
  .site-footer::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 32px;
    width: 100%;
    mix-blend-mode: overlay;
    background-color: #FB3E4B; }
  .site-footer__title {
    font-family: "Raleway", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    margin: 0 0 15px 0; }
  .site-footer__top {
    background-color: #2E3554;
    padding-top: 40px;
    padding-bottom: 20px; }
  .site-footer__bottom {
    border-top: 1px solid #424866;
    background-color: #242A43; }
    .site-footer__bottom .site-footer__inner {
      height: 80px;
      display: flex;
      align-items: center; }
  .site-footer a {
    color: #fff; }

.site-footer__inner {
  display: grid;
  grid-template-columns: 50% 50%; }

.site-footer__menu {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1; }

.site-footer__social {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  text-align: right; }

.site-footer__address {
  display: none; }

.site-footer__newsletter {
  grid-column: 1 / span 2;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin: 20px -20px 0;
  padding: 20px; }

@media screen and (min-width: 768px) {
  .site-footer__inner {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: 50% 50%; }
  .site-footer__menu {
    grid-column: 1 / span 1;
    grid-row: 1 / span 2; }
  .site-footer__social {
    grid-column: 5 / span 1;
    grid-row: 2 / span 1;
    padding-top: 20px; }
  .site-footer__address {
    display: block;
    grid-column: 2 / span 2;
    grid-row: 1 / span 2; }
  .site-footer__newsletter {
    grid-column: 4 / span 2;
    grid-row: 1 / span 1;
    border: none;
    margin: 0;
    padding: 0; } }

@media screen and (min-width: 1100px) {
  .site-footer__top {
    background-color: #2E3554;
    padding-top: 70px;
    padding-bottom: 35px; }
  .site-footer__inner {
    display: flex;
    flex-wrap: wrap; }
  .site-footer__menu {
    width: 234px; }
  .site-footer__social {
    text-align: left;
    padding: 0; }
  .site-footer__address {
    width: 360px; }
  .site-footer__newsletter {
    flex-grow: 1;
    padding-right: 40px;
    max-width: 650px; }
    .site-footer__newsletter__inner {
      max-width: 330px; } }

.site-footer__bottom .site-footer__inner {
  display: flex;
  align-items: center; }

.site-footer__bottom .site-footer__title {
  margin: 0; }

.site-footer__bottom .site-footer__sponsors {
  align-items: center;
  display: flex;
  margin-left: 20px; }

.site-footer__bottom .site-footer__privacy {
  margin-left: auto; }

.form--contact {
  margin-top: 40px;
  margin-bottom: 40px; }
  @media screen and (min-width: 600px) {
    .form--contact {
      margin-top: 60px;
      margin-bottom: 60px;
      max-width: 520px; } }

@media screen and (min-width: 600px) {
  .fieldgrid {
    display: flex;
    justify-content: space-between; } }

@media screen and (min-width: 600px) {
  .field-wrapper--firstname,
  .field-wrapper--lastname {
    width: 45%; } }

@media screen and (min-width: 600px) {
  .field-wrapper--email {
    width: 55%; } }

@media screen and (min-width: 600px) {
  .field-wrapper--phone {
    width: 35%; } }

.form {
  padding: 0;
  color: #fff; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

legend {
  color: #fff;
  margin-bottom: 1em;
  font-family: "Raleway", sans-serif;
  font-weight: 600; }

label {
  font-size: 1rem;
  margin-bottom: 0.625em;
  display: inline-block;
  color: inherit; }

.field-wrapper {
  margin-bottom: 1.5625rem; }
  .field-wrapper:last-child() {
    margin-bottom: 0; }

.field {
  position: relative; }
  .field::after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #FB3E4B; }

.field__input,
.field__textarea {
  display: block;
  width: 100%;
  font-size: 1rem;
  color: #2E3554;
  padding: 0.625em;
  border: none;
  border-radius: 1px 1px 0 0;
  outline: none; }
  .field__input::-webkit-input-placeholder, .field__textarea::-webkit-input-placeholder {
    color: #AEAEAE;
    opacity: 1; }
    .error .field__input::-webkit-input-placeholder, .error .field__textarea::-webkit-input-placeholder {
      color: #FB3E4B; }
  .field__input:-moz-placeholder, .field__textarea:-moz-placeholder {
    color: #AEAEAE;
    opacity: 1; }
    .error .field__input:-moz-placeholder, .error .field__textarea:-moz-placeholder {
      color: #FB3E4B; }
  .field__input::-moz-placeholder, .field__textarea::-moz-placeholder {
    color: #AEAEAE;
    opacity: 1; }
    .error .field__input::-moz-placeholder, .error .field__textarea::-moz-placeholder {
      color: #FB3E4B; }
  .field__input:-ms-input-placeholder, .field__textarea:-ms-input-placeholder {
    color: #AEAEAE;
    opacity: 1; }
    .error .field__input:-ms-input-placeholder, .error .field__textarea:-ms-input-placeholder {
      color: #FB3E4B; }

.field__input {
  height: 40px; }

.field__textarea {
  height: 190px; }

.form__buttons {
  margin-top: 1.5625rem; }

.custom-checkbox + .custom-checkbox {
  margin-top: 10px; }

.custom-checkbox label {
  position: relative;
  padding-left: 28px;
  color: #2E3554;
  margin: 0;
  cursor: pointer; }
  .custom-checkbox label::before, .custom-checkbox label::after {
    content: '';
    display: block;
    width: 15px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -6px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition-property: opacity;
    transition-delay: 0s;
    transition-duration: .3s;
    transition-timing-function: ease-in-out; }
  .custom-checkbox label::before {
    background-image: url("../img/ui/checkbox-inactive-outline.svg");
    opacity: 1; }
  .custom-checkbox label::after {
    background-image: url("../img/ui/checkbox-active.svg");
    opacity: 0; }

.custom-checkbox input[type="checkbox"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.custom-checkbox input[type="checkbox"]:checked + label::before {
  opacity: 0; }

.custom-checkbox input[type="checkbox"]:checked + label::after {
  opacity: 1; }

.form--footer-newsletter {
  padding: 0;
  width: 100%; }
  @media screen and (min-width: 1100px) {
    .form--footer-newsletter {
      max-width: 233px; } }
  .form--footer-newsletter .field-wrapper {
    position: relative;
    margin: 0;
    display: flex; }
  .form--footer-newsletter .field {
    width: calc(100% - 40px); }
    .form--footer-newsletter .field input {
      background-color: #242A43;
      color: #fff;
      font-size: 0.8125rem;
      letter-spacing: 0.10615em;
      font-family: "Raleway", sans-serif;
      padding: 0 0.76923em; }
      .form--footer-newsletter .field input::-webkit-input-placeholder {
        color: #fff; }
      .form--footer-newsletter .field input:-moz-placeholder {
        color: #fff; }
      .form--footer-newsletter .field input::-moz-placeholder {
        color: #fff; }
      .form--footer-newsletter .field input:-ms-input-placeholder {
        color: #fff; }
  .form--footer-newsletter .field::after {
    display: none; }
  .form--footer-newsletter .btn--small,
  .form--footer-newsletter .btn--small .icn {
    width: 40px;
    height: 40px; }
  .form--footer-newsletter .btn--small:not([disabled]):hover, .form--footer-newsletter .btn--small:not([disabled]):focus {
    background-color: #242A43; }
    .form--footer-newsletter .btn--small:not([disabled]):hover .icn, .form--footer-newsletter .btn--small:not([disabled]):focus .icn {
      width: 40px; }

.form--footer-newsletter--wrapper {
  position: relative;
  min-height: 40px;
  display: block; }

.form--footer-thankyou--container,
.form--footer-newsletter--container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 0.3s ease-in-out; }
  .form--footer-thankyou--container h1,
  .form--footer-newsletter--container h1 {
    margin: 0;
    font-size: 26px; }
  .form--footer-thankyou--container.hide,
  .form--footer-newsletter--container.hide {
    opacity: 0; }

.form--header-search {
  width: 160px;
  padding: 0; }
  @media screen and (min-width: 1280px) {
    .form--header-search {
      max-width: 215px;
      width: 100%; } }
  .form--header-search .field-wrapper {
    position: relative;
    margin: 0; }
  .form--header-search .field {
    background-color: #F5F5F5;
    margin: 0; }
    .form--header-search .field input {
      background-color: transparent;
      width: calc(100% - 35px);
      font-family: "Raleway", sans-serif;
      font-size: 0.8125rem;
      letter-spacing: 0.10615em;
      padding: 0 0.76923em; }
  .form--header-search .field::after {
    background-color: #2E3554; }
  .form--header-search .btn--small {
    color: #2E3554;
    position: absolute;
    top: 3px;
    right: 0; }
    .form--header-search .btn--small:not([disabled]):hover, .form--header-search .btn--small:not([disabled]):focus {
      color: #2E3554; }

@media screen and (min-width: 768px) {
  .trigger {
    display: none; } }

.site-header {
  position: sticky;
  top: 0px;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
  height: 48px; }
  .site-header__inner {
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    @media screen and (min-width: 768px) {
      .site-header__inner {
        padding-top: 40px; } }
    @media screen and (min-width: 1100px) {
      .site-header__inner {
        flex-wrap: nowrap;
        padding-top: 0; } }
  .site-header .logo {
    order: 2; }
  .site-header .nav--primary {
    order: 3; }
  .site-header .nav--secondary {
    order: 1; }
    @media screen and (min-width: 1100px) {
      .site-header .nav--secondary {
        order: 4; } }
  .site-header .form--header-search {
    order: 5; }
  @media screen and (min-width: 768px) {
    .site-header {
      height: 123px; } }
  @media screen and (min-width: 1100px) {
    .site-header {
      height: 83px; } }
  .site-header .form--header-search,
  .site-header .nav--primary,
  .site-header .nav--secondary {
    display: none; }
    @media screen and (min-width: 768px) {
      .site-header .form--header-search,
      .site-header .nav--primary,
      .site-header .nav--secondary {
        display: block; } }

.hero-banner {
  position: relative;
  height: 160px; }
  @media screen and (min-width: 768px) {
    .hero-banner {
      height: 200px; } }
  @media screen and (min-width: 1100px) {
    .hero-banner {
      height: 274px; } }
  .hero-banner.constricted {
    overflow: visible; }
  .hero-banner::after, .hero-banner::before {
    content: '';
    display: block;
    width: 200px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    display: none; }
    @media screen and (min-width: 768px) {
      .hero-banner::after, .hero-banner::before {
        width: 100vw;
        display: block; } }
  .hero-banner::before {
    background-color: #2E3554;
    left: 40px;
    transform: translateX(-100%); }
  .hero-banner::after {
    background-color: #FB3E4B;
    right: 40px;
    transform: translateX(100%); }
  .hero-banner .back-button {
    position: absolute;
    top: 20px;
    z-index: 3; }
    @media screen and (min-width: 1100px) {
      .hero-banner .back-button {
        top: 40px; } }
  .hero-banner .lines {
    position: absolute;
    height: 274px;
    z-index: 2;
    width: 100vw;
    right: 0;
    left: 50%;
    margin-left: 640px;
    display: none; }
    @media screen and (min-width: 1100px) {
      .hero-banner .lines {
        display: block; } }

.hero-banner__visual {
  margin: 0;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  overflow: hidden; }
  .hero-banner__visual img {
    display: block;
    height: 100%;
    width: auto;
    position: absolute;
    margin-left: 0;
    top: 50%;
    left: 0;
    transform: translate(10%, -50%); }
    @media screen and (min-width: 600px) {
      .hero-banner__visual img {
        width: 100%;
        height: auto; } }
    @media screen and (min-width: 768px) {
      .hero-banner__visual img {
        width: auto;
        height: 100%;
        transform: translate(-10%, -50%); } }
    @media screen and (min-width: 1100px) {
      .hero-banner__visual img {
        min-width: 900px;
        transform: translateY(-50%); } }

.hero-banner__doors {
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 0; }
  .hero-banner__doors::before, .hero-banner__doors::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    transform: skew(45deg);
    z-index: 0; }
  .hero-banner__doors::before {
    transform: skew(45deg); }
  .hero-banner__doors::after {
    transform: skew(-45deg); }
    @media screen and (min-width: 768px) {
      .hero-banner__doors::after {
        transform: skew(45deg); } }
  .hero-banner__doors::before {
    left: -200px;
    width: 300px;
    background-color: #2E3554; }
    @media screen and (min-width: 768px) {
      .hero-banner__doors::before {
        left: -260px; } }
    @media screen and (min-width: 1100px) {
      .hero-banner__doors::before {
        width: 400px;
        left: -190px; } }
  .hero-banner__doors::after {
    right: -50px;
    width: 100px;
    background-color: #FB3E4B; }
    @media screen and (min-width: 768px) {
      .hero-banner__doors::after {
        right: auto;
        left: 450px;
        width: 100%;
        bottom: auto; } }
    @media screen and (min-width: 1100px) {
      .hero-banner__doors::after {
        left: 740px; } }
  .hero-banner__doors.--blend {
    z-index: 0;
    mix-blend-mode: multiply; }
    .hero-banner__doors.--blend::before {
      left: -140px; }
      @media screen and (min-width: 768px) {
        .hero-banner__doors.--blend::before {
          left: -190px; } }
      @media screen and (min-width: 1100px) {
        .hero-banner__doors.--blend::before {
          left: -120px; } }
    .hero-banner__doors.--blend::after {
      right: 0; }
      @media screen and (min-width: 768px) {
        .hero-banner__doors.--blend::after {
          right: auto;
          left: 390px; } }
      @media screen and (min-width: 1100px) {
        .hero-banner__doors.--blend::after {
          left: 670px; } }
  .hero-banner__doors.--opaque {
    z-index: 0; }

.hero-banner__inner {
  z-index: 4;
  position: absolute;
  color: white;
  display: none; }
  @media screen and (min-width: 768px) {
    .hero-banner__inner {
      top: 30px;
      left: 65%;
      display: block; } }
  @media screen and (min-width: 1100px) {
    .hero-banner__inner {
      top: 50px;
      left: 830px; } }
  .hero-banner__inner .date-list {
    margin: .8em 0; }
    .hero-banner__inner .date-list li {
      display: inline-block; }

.hero-banner .nav--share {
  position: absolute;
  z-index: 9;
  right: 10%;
  top: 125px; }
  @media screen and (min-width: 768px) {
    .hero-banner .nav--share {
      top: 165px; } }
  @media screen and (min-width: 1100px) {
    .hero-banner .nav--share {
      top: 239px; } }

.hero-banner__org-logo {
  position: absolute;
  z-index: 9;
  color: #2E3554;
  bottom: 0;
  left: 440px;
  height: 34px;
  padding: 6px 20px;
  display: none; }
  @media screen and (min-width: 1100px) {
    .hero-banner__org-logo {
      display: block; } }
  .hero-banner__org-logo::before {
    content: '';
    display: block;
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: skew(45deg);
    z-index: 0; }
  .hero-banner__org-logo img {
    position: relative;
    z-index: 1; }
  .hero-banner__org-logo img + img {
    margin-left: 10px; }

.hero-banner--home {
  position: relative; }
  .hero-banner--home .hero-banner__inner {
    position: relative;
    left: auto;
    top: auto;
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding: 20px 0; }
    @media screen and (min-width: 768px) {
      .hero-banner--home .hero-banner__inner {
        padding: 0;
        align-items: center; } }
  .hero-banner--home .hero-banner__title {
    font-family: "Raleway", sans-serif;
    font-size: 1.125rem;
    font-weight: 300;
    letter-spacing: 0.11944em;
    text-transform: uppercase;
    color: #fff; }
    @media screen and (min-width: 768px) {
      .hero-banner--home .hero-banner__title {
        font-size: 1.875rem;
        letter-spacing: 0.07167em; } }
    @media screen and (min-width: 1100px) {
      .hero-banner--home .hero-banner__title {
        font-size: 2.8125rem;
        letter-spacing: 0.04778em; } }
  .hero-banner--home .hero-banner__doors.--opaque,
  .hero-banner--home .hero-banner__doors.--blend {
    position: absolute; }
    @media screen and (min-width: 768px) {
      .hero-banner--home .hero-banner__doors.--opaque::before, .hero-banner--home .hero-banner__doors.--opaque::after,
      .hero-banner--home .hero-banner__doors.--blend::before,
      .hero-banner--home .hero-banner__doors.--blend::after {
        left: auto;
        right: auto;
        margin: auto; }
      .hero-banner--home .hero-banner__doors.--opaque::before,
      .hero-banner--home .hero-banner__doors.--blend::before {
        width: 100vw;
        right: 50%;
        margin-right: 50px; }
      .hero-banner--home .hero-banner__doors.--opaque::after,
      .hero-banner--home .hero-banner__doors.--blend::after {
        width: 300px;
        right: -100px; } }
    @media screen and (min-width: 1100px) {
      .hero-banner--home .hero-banner__doors.--opaque,
      .hero-banner--home .hero-banner__doors.--blend {
        max-width: 1440px; }
        .hero-banner--home .hero-banner__doors.--opaque::before, .hero-banner--home .hero-banner__doors.--opaque::after,
        .hero-banner--home .hero-banner__doors.--blend::before,
        .hero-banner--home .hero-banner__doors.--blend::after {
          left: auto;
          right: auto;
          margin: auto; }
        .hero-banner--home .hero-banner__doors.--opaque::before,
        .hero-banner--home .hero-banner__doors.--blend::before {
          width: 100vw;
          right: 50%;
          margin-right: 200px; }
        .hero-banner--home .hero-banner__doors.--opaque::after,
        .hero-banner--home .hero-banner__doors.--blend::after {
          width: 100vw;
          left: 50%;
          margin-left: 250px; } }
  @media screen and (min-width: 768px) {
    .hero-banner--home .hero-banner__doors.--blend::before {
      width: 100vw;
      right: 50%;
      left: auto;
      margin: 0;
      margin-right: 0; }
    .hero-banner--home .hero-banner__doors.--blend::after {
      width: 300px;
      left: auto;
      margin: 0;
      right: -50px; } }
  @media screen and (min-width: 1100px) {
    .hero-banner--home .hero-banner__doors.--blend::before {
      margin-right: 130px; }
    .hero-banner--home .hero-banner__doors.--blend::after {
      margin-left: 180px; } }
  .hero-banner--home .hero-banner__visual img {
    display: block;
    height: 100%;
    width: auto;
    position: absolute;
    margin-left: 0;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%); }
    @media screen and (min-width: 600px) {
      .hero-banner--home .hero-banner__visual img {
        width: 100%;
        height: auto; } }
    @media screen and (min-width: 768px) {
      .hero-banner--home .hero-banner__visual img {
        width: auto;
        height: 100%;
        transform: translate(-30%, -50%); } }
    @media screen and (min-width: 1100px) {
      .hero-banner--home .hero-banner__visual img {
        min-width: 900px;
        transform: translate(-40%, -50%); } }

.history {
  padding: 80px 0 60px; }
  .history__timeline {
    position: relative;
    z-index: 0; }
  .history__facts {
    position: relative;
    z-index: 1; }
    .history__facts .slide {
      position: absolute;
      width: 100%; }

.facts {
  position: relative;
  min-height: 300px; }

.fact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  max-width: 400px;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .fact {
      flex-direction: row;
      max-width: 700px; } }
  @media screen and (min-width: 990px) {
    .fact {
      flex-direction: row;
      max-width: 800px; } }
  @media screen and (min-width: 1100px) {
    .fact {
      max-width: 1000px; } }
  .fact__visual {
    margin: 0;
    position: relative;
    z-index: 1; }
    @media screen and (min-width: 768px) {
      .fact__visual {
        width: 300px; } }
    @media screen and (min-width: 1100px) {
      .fact__visual {
        width: 425px; } }
    .fact__visual img {
      display: block;
      width: 100%;
      height: auto; }
  .fact__buttons {
    text-align: right;
    margin: 20px -30px -40px -30px;
    display: flex;
    justify-content: space-between; }
    @media screen and (min-width: 768px) {
      .fact__buttons {
        justify-content: flex-end; } }
    .fact__buttons button {
      display: block; }
  .fact__content {
    position: relative; }
    @media screen and (min-width: 768px) {
      .fact__content {
        width: calc(100% - 300px); } }
    @media screen and (min-width: 1100px) {
      .fact__content {
        width: calc(100% - 425px); } }
    .fact__content__inner {
      background-color: #FB3E4B;
      color: white;
      width: 100%;
      height: 100%;
      z-index: 3;
      padding: 20px 30px 40px;
      position: relative; }
      .fact__content__inner h3, .fact__content__inner h4 {
        font-family: "Raleway", sans-serif;
        font-weight: 800;
        text-transform: uppercase;
        margin: 0 0 6px 0; }
        @media screen and (min-width: 768px) {
          .fact__content__inner h3, .fact__content__inner h4 {
            margin: 0 0 8px 0; } }
        @media screen and (min-width: 1100px) {
          .fact__content__inner h3, .fact__content__inner h4 {
            margin: 0 0 10px 0; } }
      .fact__content__inner h3 {
        font-size: 1.125rem;
        letter-spacing: 0.05778em; }
        @media screen and (min-width: 768px) {
          .fact__content__inner h3 {
            font-size: 1.5rem;
            letter-spacing: 0.04333em; } }
        @media screen and (min-width: 1100px) {
          .fact__content__inner h3 {
            font-size: 1.8125rem;
            letter-spacing: 0.03586em; } }
      .fact__content__inner h4 {
        font-size: 0.875rem;
        letter-spacing: 0.15714em; }
        @media screen and (min-width: 768px) {
          .fact__content__inner h4 {
            font-size: 1rem;
            letter-spacing: 0.065em; } }
        @media screen and (min-width: 1100px) {
          .fact__content__inner h4 {
            font-size: 1.125rem;
            letter-spacing: 0.05778em; } }
      .fact__content__inner p {
        font-size: 0.75rem;
        line-height: 1.33333;
        color: #2E3554;
        margin: 0; }
        @media screen and (min-width: 768px) {
          .fact__content__inner p {
            font-size: 0.875rem;
            line-height: 1.42857; } }
        @media screen and (min-width: 1100px) {
          .fact__content__inner p {
            font-size: 1rem;
            line-height: 1.5; } }
      .fact__content__inner p + p {
        margin-top: 1em; }
    .fact__content::after, .fact__content::before {
      content: '';
      z-index: 2;
      display: block;
      position: absolute;
      bottom: -50%;
      width: 100%;
      height: 150%;
      background-color: #FB3E4B;
      transform: skewY(-30deg);
      transform-origin: bottom left;
      mix-blend-mode: multiply; }
      @media screen and (min-width: 768px) {
        .fact__content::after, .fact__content::before {
          top: auto;
          bottom: 0;
          width: 100%;
          height: 100%;
          transform: skewX(35deg);
          transform-origin: bottom left; } }
    .fact__content::after {
      z-index: 0;
      background-color: white;
      mix-blend-mode: normal; }

.timeline {
  height: 50px;
  border-top: 2px solid white;
  position: relative;
  margin-top: 80px;
  margin-left: -20px;
  margin-right: -20px; }
  @media screen and (max-width: 768px) {
    .timeline__track {
      width: 360px;
      position: absolute;
      left: 50%;
      transition: margin .3s ease-in-out; }
    [data-active-slide="1"] .timeline__track {
      margin-left: -25px; }
    [data-active-slide="2"] .timeline__track {
      margin-left: -127.85714px; }
    [data-active-slide="3"] .timeline__track {
      margin-left: -179.28571px; }
    [data-active-slide="4"] .timeline__track {
      margin-left: -230.71429px; }
    [data-active-slide="5"] .timeline__track {
      margin-left: -282.14286px; }
    [data-active-slide="6"] .timeline__track {
      margin-left: -385px; }
    [data-active-slide="7"] .timeline__track {
      margin-left: -385px; } }
  @media screen and (min-width: 768px) {
    .timeline {
      height: 84px;
      margin-left: auto;
      margin-right: auto; }
      .timeline::before, .timeline::after {
        content: '';
        position: absolute;
        display: block;
        border: 2px solid white; }
      .timeline::before {
        width: 100vw;
        height: 100%;
        border-top: none;
        border-right-width: 3px;
        transform: translateX(-100%) skewX(-40deg);
        left: -31px;
        top: -2px; }
      .timeline::after {
        width: 14px;
        height: 14px;
        border-width: 2px 2px 0 0;
        transform: rotate(45deg);
        right: 0;
        top: -8px; } }

.year {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  background-color: white;
  position: absolute;
  top: 0;
  margin-top: -12px; }
  .year::after {
    content: attr(data-year);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 10px);
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.0625em; }
  .year .line {
    display: block;
    position: absolute;
    margin: 0 0 15px 0;
    bottom: 100%;
    left: 50%;
    width: 1px;
    height: 0;
    background-color: rgba(255, 255, 255, 0.21);
    transition: height .3s ease-in-out; }
  .year::before {
    content: '';
    display: block;
    position: absolute;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border-radius: 50%;
    border: 3px solid #FB3E4B;
    opacity: 0;
    transition: opacity .3s ease-in-out; }
  .year.is-active:hover::before, .year.is-active::before {
    opacity: 1; }
  .year.is-active .line {
    height: 160px; }
  @media screen and (min-width: 1100px) {
    .year:hover {
      cursor: pointer; }
      .year:hover::before {
        opacity: .5; } }

.slide {
  display: none; }
  .slide:after {
    display: table;
    clear: both;
    content: ''; }
  .slide .fact {
    position: relative;
    margin: auto; }
  @media screen and (min-width: 768px) {
    .slide .fact {
      margin: 0; }
    .slide[data-slide="1"] .fact, .slide[data-slide="2"] .fact, .slide[data-slide="3"] .fact, .slide[data-slide="4"] .fact, .slide[data-slide="5"] .fact, .slide[data-slide="6"] .fact, .slide[data-slide="7"] .fact {
      left: 0; } }
  @media screen and (min-width: 990px) {
    .slide[data-slide="1"] .fact {
      left: 0; }
    .slide[data-slide="2"] .fact {
      left: 9%; }
    .slide[data-slide="3"] .fact {
      left: 20%; }
    .slide[data-slide="4"] .fact {
      left: auto;
      right: 30%;
      float: right; }
    .slide[data-slide="5"] .fact {
      left: auto;
      right: 25%;
      float: right; }
    .slide[data-slide="6"] .fact {
      left: auto;
      right: 15%;
      float: right; }
    .slide[data-slide="7"] .fact {
      left: auto;
      right: 0;
      float: right; } }

.year[data-slide="1"] {
  left: 0; }

.year[data-slide="2"] {
  left: 14%; }

.year[data-slide="3"] {
  left: 28%; }

.year[data-slide="4"] {
  left: 42%;
  right: auto;
  float: none; }

.year[data-slide="5"] {
  left: 56%;
  right: auto;
  float: none; }

.year[data-slide="6"] {
  left: 70%;
  right: auto;
  float: none; }

.year[data-slide="7"] {
  left: 84%;
  right: auto;
  float: none; }

@media screen and (min-width: 768px) {
  .year[data-slide="1"] {
    left: 0; }
  .year[data-slide="2"] {
    left: 20%; }
  .year[data-slide="3"] {
    left: 40%; }
  .year[data-slide="4"] {
    left: 55%; }
  .year[data-slide="5"] {
    left: 70%; }
  .year[data-slide="6"] {
    left: 80%; }
  .year[data-slide="7"] {
    left: 90%; } }

.icon-list {
  margin: 0;
  list-style: none;
  padding: 0; }
  .icon-list li {
    display: flex;
    align-items: top; }
    .icon-list li span {
      padding: .25em 0; }
    .icon-list li > .icn {
      margin-right: 10px; }
      .icon-list li > .icn svg {
        fill: currentColor; }

.svg-sprite {
  width: 0px;
  height: 0px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden; }

.icn {
  width: 35px;
  height: 35px;
  display: inline-block;
  position: relative; }
  .icn svg {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .icn--red svg {
    fill: #FB3E4B; }
  .icn--blue svg {
    fill: #2E3554; }
  .icn--white svg {
    fill: #fff; }

.hamburger {
  display: flex;
  justify-content: center;
  align-items: center; }
  .hamburger__inner {
    position: absolute; }
    .hamburger__inner, .hamburger__inner::before, .hamburger__inner::after {
      display: block;
      position: relative;
      width: 29px;
      height: 3px;
      background-color: inherit; }
    .hamburger__inner::before, .hamburger__inner::after {
      content: '';
      position: absolute; }
    .hamburger__inner::before {
      top: -8px; }
    .hamburger__inner::after {
      bottom: -8px; }

.icn--close::before, .icn--close::after {
  content: '';
  display: block;
  position: absolute;
  width: 25px;
  height: 2px;
  border-radius: 2px;
  background-color: white;
  top: 50%;
  left: 50%; }

.icn--close::before {
  transform: translate(-50%, -50%) rotate(45deg); }

.icn--close::after {
  transform: translate(-50%, -50%) rotate(-45deg); }

.info-block {
  padding: 20px;
  color: #fff;
  position: relative; }
  .info-block::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FB3E4B;
    mix-blend-mode: multiply;
    z-index: 0; }
  .info-block a:not(.btn) {
    color: inherit; }
  .info-block__inner {
    position: relative;
    z-index: 1; }
  .info-block__title {
    font-family: "Raleway", sans-serif;
    font-size: 0.875rem;
    font-weight: 900;
    line-height: 1.28571;
    letter-spacing: 0.125em;
    text-transform: uppercase;
    margin: 0;
    color: inherit; }
    @media screen and (min-width: 768px) {
      .info-block__title {
        font-size: 1.125rem;
        line-height: 1.11111;
        letter-spacing: 0.09722em; } }
    @media screen and (min-width: 1100px) {
      .info-block__title {
        font-size: 1.3125rem;
        line-height: 1.14286;
        letter-spacing: 0.08333em; } }
  .info-block__inner > p, .info-block__jobtitle, .info-block__description, .info-block__date {
    margin: 0;
    font-size: 0.75rem;
    line-height: 1.33333; }
    @media screen and (min-width: 768px) {
      .info-block__inner > p, .info-block__jobtitle, .info-block__description, .info-block__date {
        margin: 0.71429em 0;
        font-size: 0.875rem;
        line-height: 1.42857; } }
    @media screen and (min-width: 1100px) {
      .info-block__inner > p, .info-block__jobtitle, .info-block__description, .info-block__date {
        margin: 0.625em 0;
        font-size: 1rem;
        line-height: 1.5; } }
  .info-block__date span:last-child {
    display: none; }
  .info-block__button {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px; }
  .info-block .icon-list .icn svg {
    fill: #fff; }

.info-block--blue {
  background-color: #2E3554; }
  .info-block--blue::before {
    display: none; }

.grid--alternate .col:nth-child(odd) .info-block {
  position: relative; }

.grid--alternate .col:nth-child(even) .info-block {
  mix-blend-mode: multiply;
  color: #2E3554; }
  .grid--alternate .col:nth-child(even) .info-block::before {
    display: none; }
  .grid--alternate .col:nth-child(even) .info-block .info-block__title {
    color: #FB3E4B; }
  .grid--alternate .col:nth-child(even) .info-block .btn--small {
    color: #fff;
    background-color: #FB3E4B; }
    .grid--alternate .col:nth-child(even) .info-block .btn--small:not([disabled]):hover, .grid--alternate .col:nth-child(even) .info-block .btn--small:not([disabled]):focus {
      background-color: #2E3554; }

.practical-info__title {
  font-size: 1.875rem;
  font-weight: 900;
  letter-spacing: 0.07367em;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
  color: #FB3E4B;
  text-align: center;
  margin-bottom: -.2em;
  mix-blend-mode: multiply; }

.practical-info__inner {
  background-color: #2E3554;
  color: white; }
  .practical-info__inner a {
    color: inherit;
    text-decoration: none; }

.practical-info .icon-list__item {
  padding: 16px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25); }
  .practical-info .icon-list__item .table {
    width: calc(100% - 45px); }
    .practical-info .icon-list__item .table td:nth-child(2) {
      text-align: right; }

.practical-info__buy {
  padding: 20px 0;
  text-align: center; }

.date-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.tag-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.ui-datepicker-calendar {
  font-family: "Open Sans", sans-serif;
  color: #2E3554;
  border-collapse: collapse; }
  .ui-datepicker-calendar thead th {
    font-size: 20px;
    font-weight: 400;
    width: 50px;
    height: 70px;
    vertical-align: middle;
    text-align: center; }
  .ui-datepicker-calendar tbody td {
    font-size: 16px;
    border: 1px solid #ddd;
    width: 50px;
    height: 30px;
    vertical-align: middle;
    text-align: center; }
    .ui-datepicker-calendar tbody td a {
      display: block;
      width: 100%;
      height: 100%;
      line-height: 28px; }
    .ui-datepicker-calendar tbody td.ui-datepicker-unselectable {
      border: none; }
    .ui-datepicker-calendar tbody td.ui-datepicker-today {
      background-color: rgba(251, 62, 75, 0.21);
      color: white; }
    .ui-datepicker-calendar tbody td.ui-datepicker-current-day {
      background-color: #FB3E4B;
      color: white; }

.ui-datepicker-header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2E3554; }
  .ui-datepicker-header .ui-datepicker-prev {
    order: 1; }
  .ui-datepicker-header .ui-datepicker-next {
    order: 3; }
  .ui-datepicker-header .ui-datepicker-title {
    order: 2; }

.ui-datepicker-title {
  font-size: 20px;
  font-weight: 600; }

.ui-datepicker-next,
.ui-datepicker-prev {
  width: 35px;
  height: 35px;
  overflow: hidden;
  position: relative;
  cursor: pointer; }
  .ui-datepicker-next::before,
  .ui-datepicker-prev::before {
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    top: 12px;
    border-style: solid;
    border-color: #2E3554;
    border-width: 2px 2px 0 0; }
  .ui-datepicker-next .ui-icon::before,
  .ui-datepicker-prev .ui-icon::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%; }

.ui-datepicker-prev::before {
  right: 10px;
  transform: rotate(-135deg); }

.ui-datepicker-next::before {
  left: 10px;
  transform: rotate(45deg); }

.logo {
  width: 128px;
  height: 28px; }

.logo a {
  background-image: url("../img/logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden; }
  .logo a, .logo a::before {
    display: block;
    width: 100%;
    height: 100%; }
  .logo a::before {
    content: ''; }

.msg {
  display: block;
  padding: 5px;
  margin-top: -2px; }
  .msg--error {
    display: none;
    background-color: #FB3E4B; }
    .error .msg--error {
      display: block; }

.nav__item {
  display: inline-block; }

.nav--combined .nav__item {
  display: block;
  font-size: 1rem;
  font-weight: 700;
  font-family: "Raleway", sans-serif; }
  .nav--combined .nav__item a {
    display: inline-block;
    padding: 0 0 .25em 0;
    margin: 0 0 .75em 0; }
  .nav--combined .nav__item.has-children {
    position: relative; }
    .nav--combined .nav__item.has-children .icn {
      width: 20px;
      height: 20px;
      overflow: hidden;
      position: relative;
      vertical-align: middle;
      margin: -5px 0 0 5px; }
    .nav--combined .nav__item.has-children svg {
      fill: white; }
  .nav--combined .nav__item.has-children {
    position: relative; }
  .nav--combined .nav__item.is-open .icn {
    transform: rotate(-180deg); }
  .nav--combined .nav__item.is-open > .subnav {
    display: block; }
  .nav--combined .nav__item.is-closed > .subnav {
    display: none; }

.subnav {
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  padding-left: 1em; }

.mega-menu-wrapper {
  background-color: #F5F5F5;
  overflow: hidden;
  transition-property: transform, box-shadow, opacity;
  transition-duration: .3s;
  transition-delay: 0s;
  transition-timing-function: ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  opacity: 0;
  transform: translateY(-110%);
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0);
  display: none; }
  .mega-menu-wrapper.is-open {
    display: block;
    opacity: 1;
    transform: translateY(0);
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1); }
  @media screen and (min-width: 768px) {
    .mega-menu-wrapper {
      top: 123px;
      display: none; } }
  @media screen and (min-width: 1100px) {
    .mega-menu-wrapper {
      top: 83px; } }

.nav--mega {
  padding: 20px 0; }

body.show-menu {
  left: -100vw; }
  body.show-menu .off-screen-menu {
    left: 0; }
  @media screen and (min-width: 240px) {
    body.show-menu {
      left: -240px; }
      body.show-menu .off-screen-menu {
        left: calc(100vw - 240px); } }

[data-mobile-menu="open"] {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: auto;
  display: flex;
  overflow: visible; }
  [data-mobile-menu="open"]::before {
    position: absolute;
    background-color: #2E3554;
    z-index: 0;
    transform: skew(-45deg);
    width: auto;
    height: 49px;
    top: -7px;
    right: -60px;
    left: -20px; }
  [data-mobile-menu="open"] .icn {
    position: relative;
    z-index: 1; }
    [data-mobile-menu="open"] .icn .hamburger__inner {
      background-color: white; }
    [data-mobile-menu="open"] .icn svg {
      fill: white; }
  [data-mobile-menu="open"] .btn__text {
    display: inline-block;
    width: auto;
    color: white;
    position: relative;
    z-index: 1; }
  @media screen and (min-width: 768px) {
    [data-mobile-menu="open"] {
      display: none; } }

.off-screen-menu {
  background-color: #2E3554;
  color: #fff;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  left: 100vw;
  z-index: 999;
  transition: left .3s ease-in-out;
  overflow: scroll; }
  .off-screen-menu__inner {
    padding: 60px 20px 20px 25px;
    position: relative;
    display: block; }
  .off-screen-menu [data-mobile-menu="close"] {
    position: absolute;
    top: 10px;
    right: 10px; }
  @media screen and (min-width: 240px) {
    .off-screen-menu {
      width: 240px; } }
  @media screen and (min-width: 768px) {
    .off-screen-menu {
      display: none; } }
  .off-screen-menu .form--header-search {
    width: 100%;
    max-width: 100%; }
    .off-screen-menu .form--header-search .field::after {
      background-color: #FB3E4B; }
  .off-screen-menu__menu {
    margin-top: 25px; }
  .off-screen-menu__social {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding-top: 30px;
    margin-top: 60px; }
    .off-screen-menu__social h5 {
      margin: 0 0 14px 0; }

.nav--primary {
  font-size: 0.9375rem;
  letter-spacing: 0.05267em;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  flex-grow: 1;
  margin-left: 10px;
  width: auto; }
  .nav--primary .nav__item {
    color: #242A43; }
    .nav--primary .nav__item[data-target=nav-Educatie] {
      margin-top: -3px; }
      .nav--primary .nav__item[data-target=nav-Educatie] a,
      .nav--primary .nav__item[data-target=nav-Educatie] i {
        vertical-align: middle; }
      .nav--primary .nav__item[data-target=nav-Educatie] a {
        margin-top: -2px; }
      @supports (-ms-ime-align: auto) {
        .nav--primary .nav__item[data-target=nav-Educatie] a {
          margin-top: -5px; } }
  .nav--primary li {
    padding: 0 5px; }
    .nav--primary li.is-active a, .nav--primary li:hover a, .nav--primary li:focus a {
      color: #FB3E4B; }
  @media screen and (min-width: 768px) {
    .nav--primary {
      margin-left: 20px; }
      .nav--primary li {
        padding: 0 10px; } }
  @media screen and (min-width: 1100px) {
    .nav--primary {
      margin-left: 20px; }
      .nav--primary li {
        padding: 0 5px; } }
  @media screen and (min-width: 1280px) {
    .nav--primary {
      margin-left: 40px; }
      .nav--primary li {
        padding: 0 14px; } }
  @media screen and (min-width: 1440px) {
    .nav--primary {
      margin-left: 80px; }
      .nav--primary li {
        padding: 0 24px; } }

.nav--secondary {
  font-size: 0.8125rem;
  letter-spacing: 0.05231em;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #f2f2f2;
  position: relative; }
  .nav--secondary > ul > li.is-active > a, .nav--secondary > ul > li:hover > a, .nav--secondary > ul > li:focus > a {
    color: #FB3E4B; }
  @media screen and (min-width: 768px) {
    .nav--secondary {
      height: 40px;
      color: #fff;
      top: 0;
      left: 0;
      right: 0;
      position: absolute; }
      .nav--secondary > ul {
        height: 100%;
        text-align: right;
        display: inline-flex;
        position: absolute;
        align-items: center;
        right: 0;
        padding: 0 30px 0 20px; }
        .nav--secondary > ul::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: -20px;
          left: 0;
          bottom: 0;
          background-color: #2E3554;
          transform: skew(-45deg); }
      .nav--secondary > ul > li {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        position: relative;
        z-index: 1;
        height: 3.125rem; }
      .nav--secondary svg {
        fill: currentColor; } }
  @media screen and (min-width: 1100px) {
    .nav--secondary {
      margin-right: 15px;
      width: auto;
      height: auto;
      color: #2E3554;
      position: relative;
      border: none; }
      .nav--secondary > ul {
        text-align: left;
        height: auto;
        position: relative;
        right: auto;
        padding: 0; }
        .nav--secondary > ul::before {
          display: none; }
      .nav--secondary > ul > li {
        padding: 0 10px;
        display: inline-flex; } }
  @media screen and (min-width: 1280px) {
    .nav--secondary {
      margin-right: 25px; }
      .nav--secondary > ul > li {
        padding: 0 14px; } }
  @media screen and (min-width: 1440px) {
    .nav--secondary {
      margin-right: 35px; }
      .nav--secondary > ul > li {
        padding: 0 20px; } }

.nav--secondary .nav__item > .subnav,
.nav--secondary .nav__item.is-closed > .subnav {
  display: none; }

.nav--secondary .nav__item:hover .icn,
.nav--secondary .nav__item.is-open .icn {
  transform: rotate(-180deg); }

.nav--secondary .nav__item:hover > .subnav,
.nav--secondary .nav__item.is-open > .subnav {
  display: block; }

.nav--secondary .icn {
  width: auto;
  height: auto;
  margin-left: 0.625rem; }

.nav--secondary .has-children {
  display: inline-flex;
  align-items: center; }

.nav--secondary .subnav {
  position: absolute;
  background-color: #fff;
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  top: 2.5rem;
  padding: 1.25rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  text-align: center; }
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    .nav--secondary .subnav {
      color: #2E3554; } }
  .nav--secondary .subnav li:hover {
    color: #FB3E4B; }

.nav--secondary .subnav__item + .subnav__item {
  margin-top: 0.625rem; }

.nav--share .btn {
  display: block; }
  .nav--share .btn:not(.btn--primary):hover {
    background-color: white;
    color: #FB3E4B; }

.nav--share a:hover:after {
  content: none; }

.nav--share .nav__list {
  width: 35px;
  display: none; }

.nav--share .nav__item {
  display: block; }

.nav--share.is-hovered .nav__list {
  display: block; }

.nav--social a {
  position: relative;
  display: block;
  width: 37px;
  height: 37px;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  background-color: transparent;
  transition-property: background-color;
  transition-duration: .3s;
  transition-timing-function: ease-in-out; }
  .nav--social a::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%; }
  .nav--social a .icn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .nav--social a svg {
    fill: #fff;
    transition-property: fill;
    transition-duration: .3s;
    transition-timing-function: ease-in-out; }
  .nav--social a:hover, .nav--social a:focus {
    background-color: white; }
    .nav--social a:hover svg, .nav--social a:focus svg {
      fill: #FB3E4B; }

.pagination {
  margin: 0; }
  .pagination__list {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
  .pagination__item:nth-last-child(2) .pagination__nav {
    border-right: none; }
  .pagination__nav {
    position: relative;
    border-style: solid;
    border-color: #FB3E4B rgba(251, 62, 75, 0.21);
    border-width: 1px 1px 1px 0;
    width: 55px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .3s ease-in-out; }
    .pagination__nav:not([disabled]):hover, .pagination__nav:not([disabled]):focus {
      background-color: rgba(251, 62, 75, 0.21); }
    .is-active .pagination__nav {
      background-color: rgba(251, 62, 75, 0.21); }
  .pagination__nav--prev, .pagination__nav--next {
    display: block;
    border: none;
    background-color: #FB3E4B;
    color: #fff; }
    .pagination__nav--prev::before, .pagination__nav--next::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden; }
    .pagination__nav--prev .icn, .pagination__nav--next .icn {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .pagination__nav--prev .icn svg, .pagination__nav--next .icn svg {
      fill: currentColor; }
    .pagination__nav--prev:not([disabled]):hover, .pagination__nav--prev:not([disabled]):focus, .pagination__nav--next:not([disabled]):hover, .pagination__nav--next:not([disabled]):focus {
      background-color: #FB3E4B; }
  .pagination__nav--prev {
    clip-path: polygon(0 14px, 14px 0, 100% 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 100%); }
  .pagination__nav--next {
    clip-path: polygon(0 0, 0 0, 100% 0, 100% 0, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%, 0 100%); }

.pagination__container {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%); }

.tui-page-btn {
  font-weight: 400;
  width: 55px;
  height: 50px;
  position: relative;
  border-style: solid;
  border-color: #FB3E4B rgba(251, 62, 75, 0.21);
  border-width: 1px 1px 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .3s ease-in-out;
  cursor: pointer; }
  .tui-page-btn.tui-first, .tui-page-btn.tui-last {
    display: none; }
  .tui-page-btn:hover, .tui-page-btn.tui-is-selected {
    background-color: rgba(251, 62, 75, 0.21); }
  .tui-page-btn.tui-prev, .tui-page-btn.tui-next {
    border: none;
    background-color: #FB3E4B;
    color: #fff; }
  .tui-page-btn.tui-prev {
    clip-path: polygon(0 14px, 14px 0, 100% 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 100%); }
  .tui-page-btn.tui-next {
    clip-path: polygon(0 0, 0 0, 100% 0, 100% 0, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%, 0 100%); }
  .tui-page-btn .tui-ico-prev,
  .tui-page-btn .tui-ico-next {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center; }
    .tui-page-btn .tui-ico-prev::before,
    .tui-page-btn .tui-ico-next::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%; }
  .tui-page-btn .tui-ico-prev {
    background-image: url("../img/icons/icn-arrow-left-white.svg"); }
  .tui-page-btn .tui-ico-next {
    background-image: url("../img/icons/icn-arrow-right-white.svg"); }
  .tui-page-btn.tui-is-disabled {
    cursor: not-allowed; }
    .tui-page-btn.tui-is-disabled .tui-ico-prev,
    .tui-page-btn.tui-is-disabled .tui-ico-next {
      opacity: .5; }

.lines {
  background-image: linear-gradient(90deg, #fff 1px, transparent 1px);
  background-size: 10px 10px;
  background-position: 0 0; }

.search-results {
  color: #2E3554; }
  .search-results__list {
    list-style: none;
    padding: 0;
    margin: 0; }
  .search-results__item {
    display: flex;
    align-items: center; }
    .search-results__item > .icn > svg {
      fill: #FB3E4B; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.carousel--events:not(.slick-slider),
.carousel--events:not(.slick-initialized) {
  display: flex; }

.carousel--events {
  margin: 40px 0 0; }
  .carousel--events .slick-track {
    padding: 40px 0; }
  .carousel--events .block--event {
    position: relative;
    transition-property: transform;
    transition-duration: .3s;
    transition-timing-function: ease-in-out; }
    .carousel--events .block--event .block-meta__logo-bar,
    .carousel--events .block--event .info-block__tags,
    .carousel--events .block--event .info-block::before {
      display: none; }
    .carousel--events .block--event .block-meta {
      left: 0;
      right: 0; }
    .carousel--events .block--event .block__visual {
      margin: 0;
      overflow: hidden;
      transition-property: background-color;
      transition-duration: .3s;
      transition-timing-function: ease-in-out;
      outline: none; }
      .carousel--events .block--event .block__visual img {
        transition: transform .3s ease-in-out; }
    .carousel--events .block--event .info-block {
      opacity: 0;
      z-index: -999;
      transition-property: opacity, z-index;
      transition-duration: .3s;
      transition-timing-function: ease-in-out;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0; }
    .carousel--events .block--event .info-block__date {
      margin: 0; }
  .carousel--events .slick-current .block--event {
    z-index: 1;
    transform: scale(1.1); }
    .carousel--events .slick-current .block--event .block__visual {
      background-color: #FB3E4B; }
    .carousel--events .slick-current .block--event .info-block {
      opacity: 1;
      z-index: 1; }

.carousel--events .slick-dots {
  list-style: none;
  padding: 0;
  margin: 40px 0 20px;
  width: 100%;
  text-align: center; }
  .carousel--events .slick-dots li {
    display: inline-block; }
  .carousel--events .slick-dots button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0 4px;
    position: relative;
    overflow: hidden;
    transition-property: background-color;
    transition-duration: .3s;
    transition-timing-function: ease-in-out; }
    .carousel--events .slick-dots button::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%; }
  .carousel--events .slick-dots li.slick-active button {
    background-color: #FB3E4B; }

.tag {
  font-size: 0.8125rem;
  letter-spacing: 0.10769em;
  height: 22px;
  color: #fff;
  text-transform: lowercase;
  line-height: 22px;
  display: inline-block; }
  .tag + .tag {
    margin-left: 10px; }
  .tag__inner {
    display: flex;
    flex-wrap: nowrap; }
  .tag__left {
    width: 10px;
    height: 22px; }
  .tag__main {
    height: 22px;
    margin-left: -2px;
    padding: 0 7px;
    background-color: #010102; }
  .tag svg {
    display: block;
    width: 10px;
    height: 22px;
    fill: #010102; }
  .tag--lightorange .tag__main {
    background-color: #FBBFA1; }
  .tag--lightorange svg {
    fill: #FBBFA1; }
  .tag--lightblue .tag__main {
    background-color: #B0CDEA; }
  .tag--lightblue svg {
    fill: #B0CDEA; }
  .tag--lightgreen .tag__main {
    background-color: #87CFC4; }
  .tag--lightgreen svg {
    fill: #87CFC4; }

.section--education {
  overflow: hidden;
  padding: 20px 0 60px; }
  @media screen and (min-width: 600px) {
    .section--education {
      padding: 40px 0 80px; }
      .section--education .block__visual {
        position: relative; }
      .section--education .block:nth-child(even) {
        position: relative; }
        .section--education .block:nth-child(even)::before {
          content: '';
          display: block;
          position: absolute;
          background-color: #FB3E4B;
          mix-blend-mode: multiply;
          top: -40px;
          width: 100%;
          height: 128px;
          z-index: 1; }
      .section--education .block:nth-child(1) .block__visual::before,
      .section--education .block:nth-child(3) .block__visual::after {
        content: '';
        position: absolute;
        display: block;
        width: 100vw;
        height: 90px;
        z-index: -1;
        top: calc(50% - 45px);
        background-image: linear-gradient(0deg, #FB3E4B 2px, transparent 2px);
        background-size: 8px 8px;
        background-position: 0 -6px; }
      .section--education .block:nth-child(1) .block__visual::before {
        transform: translateX(-100%); } }
  @media screen and (min-width: 1100px) {
    .section--education {
      padding: 90px 0 160px; } }

.section--events {
  padding-bottom: 40px; }
  .section--events::after {
    content: '';
    background-color: #fff;
    display: block;
    position: absolute;
    width: 50%;
    height: 65px;
    bottom: -50px;
    right: -220px;
    transform: skew(-45deg); }
    @media screen and (min-width: 768px) {
      .section--events::after {
        bottom: 0; } }
  .section--events .section__button {
    display: flex;
    justify-content: center;
    align-items: center; }

.section--for-rent {
  padding: 20px 0;
  text-align: center; }
  @media screen and (min-width: 600px) {
    .section--for-rent {
      padding: 40px 0; } }
  @media screen and (min-width: 1100px) {
    .section--for-rent {
      padding: 80px 0; } }
  .section--for-rent__content {
    max-width: 610px;
    margin: 50px auto 0;
    padding: 0 20px;
    color: #242A43;
    line-height: 1.5rem; }
    .section--for-rent__content h4 {
      color: #FB3E4B;
      margin: 0; }
    .section--for-rent__content p {
      margin: 0; }
      .section--for-rent__content p strong {
        color: #FB3E4B; }
    .section--for-rent__content .btn {
      margin-top: 50px; }

@media screen and (min-width: 1100px) {
  .section--news .background-bar {
    position: relative;
    transform: translateY(0);
    top: auto;
    margin-top: 0; } }

.section--partners {
  margin: 40px 0; }
  @media screen and (min-width: 768px) {
    .section--partners {
      margin: 50px 0 80px 0; } }
  @media screen and (min-width: 1100px) {
    .section--partners {
      margin: 50px 0 100px 0; } }

.section--team {
  margin: 50px 0 0; }

.page--agenda {
  position: relative;
  margin-bottom: 60px; }

.grid--agenda .block--event .block__visual {
  overflow: hidden; }
  .grid--agenda .block--event .block__visual img {
    transition: transform .3s ease-in-out; }

.grid--empty {
  padding: 150px 0;
  display: none; }
  .grid--empty.show {
    display: block; }

.faq {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto; }
  .faq__section + .faq__section {
    margin-top: 3.125rem; }

.stylesheet-section {
  margin: 50px 0;
  padding-bottom: 50px;
  border-bottom: 1px solid rgba(46, 53, 84, 0.25); }

.filler {
  min-height: 50vh; }
