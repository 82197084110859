.nav--social {
  a {
    position: relative;
    display: block;
    width: 37px;
    height: 37px;
    border: 2px solid $white;
    border-radius: 50%;
    overflow: hidden;
    background-color: transparent;
    transition-property: background-color;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
    }

    .icn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    svg {
      fill: $white;
      transition-property: fill;
      transition-duration: .3s;
      transition-timing-function: ease-in-out;
    }

    &:hover,
    &:focus {
      background-color: white;

      svg {
        fill: $red;
      }
    }
  }
}
