.history {
  padding: 80px 0 60px;

  &__timeline {
    position: relative;
    z-index: 0;
  }

  &__facts {
    position: relative;
    z-index: 1;

    .slide {
      position: absolute;
      width: 100%;
    }
  }
}

.facts {
  position: relative;
  min-height: 300px;
}

.fact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  max-width: 400px;
  overflow: hidden;

  @include above($tablet) {
    flex-direction: row;
    max-width: 700px;
  }

  @include above($desktop-sm) {
    flex-direction: row;
    max-width: 800px;
  }

  @include above($desktop) {
    max-width: 1000px;
  }

  &__visual {
    margin: 0;
    position: relative;
    z-index: 1;

    @include above($tablet) {
      width: 300px;
    }

    @include above($desktop) {
      width: 425px;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__buttons {
    text-align: right;
    margin: 20px -30px -40px -30px;
    display: flex;
    justify-content: space-between;

    @include above($tablet) {
      justify-content: flex-end;
    }

    button {
      display: block;
    }
  }

  &__content {
    position: relative;

    @include above($tablet) {
      width: calc(100% - 300px);
    }
    @include above($desktop) {
      width: calc(100% - 425px);
    }

    &__inner {
      background-color: $red;
      color: white;
      width: 100%;
      height: 100%;
      z-index: 3;
      padding: 20px 30px 40px;
      position: relative;

      h3, h4 {
        font-family: $title-font-family;
        font-weight: 800;
        text-transform: uppercase;
        margin: 0 0 6px 0;

        @include above($tablet) {
          margin: 0 0 8px 0;
        }

        @include above($desktop) {
          margin: 0 0 10px 0;
        }
      }

      h3 {
        $fs: 18;
        font-size: rem($fs);
        letter-spacing: ls(1.04, $fs);

        @include above($tablet) {
          $fs: 24;
          font-size: rem($fs);
          letter-spacing: ls(1.04, $fs);
        }

        @include above($desktop) {
          $fs: 29;
          font-size: rem($fs);
          letter-spacing: ls(1.04, $fs);
        }
      }

      h4 {
        $fs: 14;
        font-size: rem($fs);
        letter-spacing: ls(2.2, $fs);

        @include above($tablet) {
          $fs: 16;
          font-size: rem($fs);
          letter-spacing: ls(1.04, $fs);
        }

        @include above($desktop) {
          $fs: 18;
          font-size: rem($fs);
          letter-spacing: ls(1.04, $fs);
        }
      }

      p {
        $fs: 12;
        font-size: rem($fs);
        line-height: lh(16, $fs);
        color: $blue;
        margin: 0;

        @include above($tablet) {
          $fs: 14;
          font-size: rem($fs);
          line-height: lh(20, $fs);
        }

        @include above($desktop) {
          $fs: 16;
          font-size: rem($fs);
          line-height: lh(24, $fs);
        }
      }

      p + p {
        margin-top: 1em;
      }
    }
    &::after,
    &::before {
      content: '';
      z-index: 2;
      display: block;
      position: absolute;
      bottom: -50%;
      width: 100%;
      height: 150%;
      background-color: $red;
      transform: skewY(-30deg);
      transform-origin: bottom left;
      mix-blend-mode: multiply;

      @include above($tablet) {
        top: auto;
        bottom: 0;
        width: 100%;
        height: 100%;
        transform: skewX(35deg);
        transform-origin: bottom left;
      }
    }

    &::after {
      z-index: 0;
      background-color: white;
      mix-blend-mode: normal;
    }
  }
}


.timeline {
  $mobile-track-width: 360px;
  height: 50px;
  border-top: 2px solid white;
  position: relative;
  margin-top: 80px;
  margin-left: -20px;
  margin-right: -20px;

  @include below($tablet) {
    &__track {
      width: 360px;
      position: absolute;
      left: 50%;
      transition: margin .3s ease-in-out;
    }

    [data-active-slide="1"] & {
      &__track {
        margin-left: -25px;
      }
    }

    [data-active-slide="2"] & {
      &__track {
        margin-left: -(($mobile-track-width / 7*2) + 25);
      }
    }

    [data-active-slide="3"] & {
      &__track {
        margin-left: -(($mobile-track-width / 7*3) + 25);
      }
    }
    [data-active-slide="4"] & {
      &__track {
        margin-left: -(($mobile-track-width / 7*4) + 25);
      }
    }

    [data-active-slide="5"] & {
      &__track {
        margin-left: -(($mobile-track-width / 7*5) + 25);
      }
    }

    [data-active-slide="6"] & {
      &__track {
        margin-left: -(($mobile-track-width/7*7)+25);
      }
    }
    [data-active-slide="7"] & {
      &__track {
        margin-left: -(($mobile-track-width) + 25 );
      }
    }
  }

  @include above($tablet) {
    height: 84px;
    margin-left: auto;
    margin-right: auto;

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      border: 2px solid white;
    }

    &::before {
      width: 100vw;
      height: 100%;
      border-top: none;
      border-right-width: 3px;
      transform: translateX(-100%) skewX(-40deg);
      left: -31px;
      top: -2px;
    }

    &::after {
      width: 14px;
      height: 14px;
      border-width: 2px 2px 0 0;
      transform: rotate(45deg);
      right: 0;
      top: -8px;
    }
  }
}


.year {
  $offset: 6px;
  @include circle(25px);
  display: inline-block;
  background-color: white;
  position: absolute;
  top: 0;
  margin-top: -12px;

  &::after {
    $fs: 16;
    content: attr(data-year);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 10px);
    font-weight: 600;
    font-size: rem($fs);
    letter-spacing: ls(1, $fs);
  }


  .line {
    display: block;
    position: absolute;
    margin: 0 0 15px 0;
    bottom: 100%;
    left: 50%;
    width: 1px;
    height: 0;
    background-color: rgba(white, .21);
    transition: height .3s ease-in-out;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -($offset);
    right: -($offset);
    bottom: -($offset);
    left: -($offset);
    border-radius: 50%;
    border: 3px solid $red;
    opacity: 0;
    transition: opacity .3s ease-in-out
  }

  &.is-active {
    &:hover::before,
    &::before {
      opacity: 1;
    }

    .line {
      height: 160px;
    }
  }

  @include above($desktop) {
    &:hover {
      cursor: pointer;

      &::before {
        opacity: .5;
      }
    }
  }
}

.slide {
  @include clearfix();
  display: none;

  .fact {
    position: relative;
    margin: auto;
  }


  
  @include above($tablet) {

    .fact {
      margin: 0;
    }

    &[data-slide="1"],
    &[data-slide="2"],
    &[data-slide="3"],
    &[data-slide="4"],
    &[data-slide="5"],
    &[data-slide="6"],
    &[data-slide="7"], {
      .fact {
        left: 0;
      }
    }

  }

  @include above($desktop-sm) {
    &[data-slide="1"] {
      .fact {
        left: 0;
      }
    }

    &[data-slide="2"] {
      .fact {
        left: 9%;
      }
    }

    &[data-slide="3"] {
      .fact {
        left: 20%;
      }
    }

    &[data-slide="4"] {
      .fact {
        left: auto;
        right: 30%;
        float: right;
      }
    }

    &[data-slide="5"] {
      .fact {
        left: auto;
        right: 25%;
        float: right;
      }
    }

    &[data-slide="6"] {
      .fact {
        left: auto;
        right: 15%;
        float: right;
      }
    }

    &[data-slide="7"] {
      .fact {
        left: auto;
        right: 0;
        float: right;
      }
    }
  }
}

.year {
  &[data-slide="1"] {
    left: 0;
  }

  &[data-slide="2"] {
    left: 14%;
  }

  &[data-slide="3"] {
    left: 28%;
  }
  &[data-slide="4"] {
    left: 42%;
    right: auto;
    float: none;
  }

  &[data-slide="5"] {
    left: 56%;
    right: auto;
    float: none;
  }

  &[data-slide="6"] {
    left: 70%;
    right: auto;
    float: none;
  }
  &[data-slide="7"] {
    left: 84%;
    right: auto;
    float: none;
  }

  @include above($tablet) {
    &[data-slide="1"] {
      left: 0;
    }
  
    &[data-slide="2"] {
      left: 20%;
    }
  
    &[data-slide="3"] {
      left: 40%;
    }
    &[data-slide="4"] {
      left: 55%;
    }
  
    &[data-slide="5"] {
      left: 70%;
    }
  
    &[data-slide="6"] {
      left: 80%;
    }
    &[data-slide="7"] {
      left: 90%;
    }
  
  }
}
