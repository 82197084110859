.grid--agenda {
  $gutter: 12px;
  margin: 0 -($gutter/2);

  .col {
    padding: 0 ($gutter/2);
    margin: 0 0 100px 0;

    @include above($tablet-xs) {
      width: 50%;
    }

    @include above($desktop-sm) {
      width: 33.333%;
    }

    @include above($desktop) {
      width: 25%;
    }
  }
}
