.nav--share {
  .btn {
    display: block;

    &:not(.btn--primary) {
      &:hover {
        background-color: white;
        color: $red;
      }
    }
  }

  a {
    &:hover {
      &:after {
        content: none;
      }
    }
  }

  .nav__list {
    width: 35px;
    display: none;
  }

  .nav__item {
    display: block;
  }

  &.is-hovered .nav__list {
    display: block;
  }
}
