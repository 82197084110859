.custom-checkbox {

  & + & { margin-top: 10px; }
  
  label {
    position: relative;
    padding-left: 28px;
    color: $blue;
    margin: 0;
    cursor: pointer;


    &::before,
    &::after {
      content: '';
      display: block;
      width: 15px;
      height: 14px;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -6px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transition-property: opacity;
      transition-delay: 0s;
      transition-duration: .3s;
      transition-timing-function: ease-in-out;
    }

    &::before {
      background-image: url('../img/ui/checkbox-inactive-outline.svg');
      opacity: 1;
    }

    &::after {
      background-image: url('../img/ui/checkbox-active.svg');
      opacity: 0;
    }
  }
  
  input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;

  }

  input[type="checkbox"]:checked + label {
    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }
  }
}