$header-height: 274px;
$header-height-tablet: 200px;
$header-height-mobile: 160px;

.hero-banner {
  position: relative;
  height: $header-height-mobile;

  @include above($tablet) {
    height: $header-height-tablet;
  }

  @include above($desktop) {
    height: $header-height;
  }

  &.constricted {
    overflow: visible;
  }

  &::after,
  &::before {
    content: '';
    display: block;
    width: 200px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    display: none;

    @include above($tablet) {
      width: 100vw;
      display: block;
    }
  }

  &::before {
    background-color: $blue;
    left: 40px;
    transform: translateX(-100%);
  }

  &::after {
    background-color: $red;
    right: 40px;
    transform: translateX(100%);
  }

  .back-button {
    position: absolute;
    top: 20px;
    z-index: 3;

    @include above($desktop) {
      top: 40px;
    }
  }

  .lines {
    position: absolute;
    height: $header-height;
    z-index: 2;
    width: 100vw;
    right: 0;
    left: 50%;
    margin-left: 640px;
    display: none;

    @include above($desktop) {
      display: block;
    }
  }
}

.hero-banner__visual {
  margin: 0;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  overflow: hidden;

  img {
    display: block;
    height: 100%;
    width: auto;
    position: absolute;
    margin-left: 0;
    top: 50%;
    left: 0;
    transform: translate(10%, -50%);

    @include above($tablet-xs) {
      width: 100%;
      height: auto;
    }

    @include above($tablet) {
      width: auto;
      height: 100%;
      transform: translate(-10%, -50%);
    }

    @include above($desktop) {
      min-width: 900px;
      transform: translateY(-50%);
    }
  }
}

.hero-banner__doors {
  position: absolute;
  width: 100vw;
  height: 100%;
  // overflow-x: hidden;
  z-index: 0;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    transform: skew(45deg);
    z-index: 0;
  }

  &::before {
    transform: skew(45deg);
  }

  &::after {
    transform: skew(-45deg);

    @include above($tablet) {
      transform: skew(45deg);
    }
  }


  &::before {
    left: -200px;
    width: 300px;
    background-color: $blue;

    @include above($tablet) {
      left: -260px;
    }

    @include above($desktop) {
      width: 400px;
      left: -190px;
    }
  }

  &::after {
    right: -50px;
    width: 100px;
    background-color: $red;

    @include above($tablet) {
      right: auto;
      left: 450px;
      width: 100%;
      bottom: auto;
    }

    @include above($desktop) {
      left: 740px;
    }
  }

  &.--blend {
    z-index: 0;
    mix-blend-mode: multiply;

    &::before {
      left: -140px;

      @include above($tablet) {
        left: -190px;
      }

      @include above($desktop) {
        left: -120px;
      }
    }

    &::after {
      right: 0;

      @include above($tablet) {
        right: auto;
        left: 390px;
      }

      @include above($desktop) {
        left: 670px;
      }
    }
  }

  &.--opaque {
    z-index: 0;
  }


}


.hero-banner__inner {
  z-index: 4;
  position: absolute;
  color: white;
  display: none;

  @include above($tablet) {
    top: 30px;
    left: 65%;
    display: block;
  }

  @include above($desktop) {
    top: 50px;
    left: 830px;
  }

  .date-list {
    margin: .8em 0;
    li { display: inline-block; }
  }
}


.hero-banner .nav--share {
  position: absolute;
  z-index: 9;
  right: 10%;
  top: 125px;

  @include above($tablet) {
    top: 165px;
  }

  @include above($desktop) {
    top: 239px;
  }
}


.hero-banner__org-logo {
  position: absolute;
  z-index: 9;
  color: $blue;
  bottom: 0;
  left: 440px;
  height: 34px;
  padding: 6px 20px;
  display: none;

  @include above($desktop) {
    display: block;
  }

  &::before {
    content:'';
    display: block;
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: skew(45deg);
    z-index: 0;
  }

  img {
    position: relative;
    z-index: 1;
  }

  img + img {
    margin-left: 10px;
  }
}



.hero-banner--home {
  position: relative;

  .hero-banner__inner {
    position: relative;
    left: auto;
    top: auto;
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding: 20px 0;

    @include above($tablet) {
      padding: 0;
      align-items: center;
    }
  }

  .hero-banner__title {
    $fs: 18;
    font-family: $title-font-family;
    font-size: rem($fs);
    font-weight: 300;
    letter-spacing: ls(2.15, $fs);
    text-transform: uppercase;
    color: $white;

    @include above($tablet) {
      $fs: 30;
      font-size: rem($fs);
      letter-spacing: ls(2.15, $fs);
    }

    @include above($desktop) {
      $fs: 45;
      font-size: rem($fs);
      letter-spacing: ls(2.15, $fs);
    }
  }


  .hero-banner__doors.--opaque,
  .hero-banner__doors.--blend {
    position: absolute;

    @include above($tablet) {
      &::before,
      &::after {
        left: auto;
        right: auto;
        margin: auto;
      }

      &::before{
        width: 100vw;
        right: 50%;
        margin-right: 50px;
      }

      &::after {
        width: 300px;
        right: -100px;
      }
    }

    @include above($desktop) {
      max-width: 1440px;
      &::before,
      &::after {
        left: auto;
        right: auto;
        margin: auto;
      }

      &::before{
        width: 100vw;
        right: 50%;
        margin-right: 200px;
      }

      &::after {
        width: 100vw;
        left: 50%;
        margin-left: 250px;
      }
    }

  }

  .hero-banner__doors.--blend {
    @include above($tablet) {
      &::before{
        width: 100vw;
        right: 50%;
        left: auto;
        margin: 0;
        margin-right: 0;
      }

      &::after {
        width: 300px;
        left: auto;
        margin: 0;
        right: -50px;
      }
    }

    @include above($desktop) {

      &::before{
        margin-right: 130px;
      }

      &::after {
        margin-left: 180px;
      }
    }
  }


  .hero-banner__visual {

    img {
      display: block;
      height: 100%;
      width: auto;
      position: absolute;
      margin-left: 0;
      top: 50%;
      left: 50%;
      transform: translate(-40%, -50%);

      @include above($tablet-xs) {
        width: 100%;
        height: auto;
      }

      @include above($tablet) {
        width: auto;
        height: 100%;
        transform: translate(-30%, -50%);
      }

      @include above($desktop) {
        min-width: 900px;
        transform: translate(-40%, -50%);
      }
    }
  }
}
