.section--partners {
  margin: 40px 0;

  @include above($tablet) {
    margin: 50px 0 80px 0;
  }

  @include above($desktop) {
    margin: 50px 0 100px 0;
  }
}

.section--team {
  margin: 50px 0 0;
}
